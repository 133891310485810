import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  background-color: #ebf1ff;
  border-radius: 5px;
  display: inline;
  padding: 15px;
`;

export const PageHeaderText = styled.span`
  font-size: 18px;
  font-family: Poppins;
  letter-spacing: 0.95px;
  color: #286cff !important;
  display: inline;
  opacity: 1 !important;
  text-align: center;
`;
