import {React, useState} from "react";
import "./index.scss";
import { withRouter, useLocation } from "react-router-dom";
import { put, post} from "../../utils/api";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { confirmAlert } from "react-confirm-alert"; 
import "react-confirm-alert/src/react-confirm-alert.css"; 
import "./confirm-alert-custom.css"; 
import { toast } from "react-toastify";
import CreateQuizList from "../../components/Quiz/CreateQuizList";

const CreateQuiz = ({match, inputQuiz, allQuizQuestions, EditMode, isQuizModalOpen, refetchQuizQuestions, refetchQuizzes }) => {

const [selectedQuestions, setSelectedQuestions] = useState(inputQuiz ? inputQuiz.questions : []);
const [quizName, setQuizName] = useState(inputQuiz ? inputQuiz.Name : '');
const courseId = match.params.id;
const lessonId = match.params.lesson;
const [CreateModalOpen, setCreateModalOpen] = useState(false);

  const handleQuizSubmit = async () => {
    if (!quizName) {
      toast.error("Quiz name is required");
      return;
    }
  
    if (selectedQuestions.length === 0) {
      toast.error("At least one question must be selected");
      return;
    }
  
    const quizData = {
      Name: quizName,
      courseId,
      lessonId,
      questions: selectedQuestions.map(q => q._id),
    };
  
    confirmAlert({
      title: "Confirm to submit",
      message: `Are you sure you want to ${EditMode ? 'edit' : 'create'} this quiz?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = EditMode
                ? await put(`/quiz/${inputQuiz._id}`, quizData, true)
                : await post('/quiz/create', quizData, true);
  
              if (response.data) {
                refetchQuizzes();
                toast.success(`Quiz ${EditMode ? 'edited' : 'created'} successfully!`);
                isQuizModalOpen(false);
              } else {
                toast.error(`Failed to ${EditMode ? 'edit' : 'create'} quiz`);
              }
            } catch (error) {
              toast.error(`An error occurred while ${EditMode ? 'editing' : 'creating'} the quiz`);
            }
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };


  const handleClick = () => {
      setCreateModalOpen(true);
  }
    return (
        <div className="QuizQuestions__page">
        <div className="w-full px-0">
          <main className="w-full" >
            <div className="top__wrapper block md:flex justify-between rounded-lg items-center my-16">
              <div className="py-6">
                <p className="name capitalize" style={{ marginLeft: '200px' }}> { EditMode ?  "Editing: " + quizName : "Create a new Quiz" } </p>
              </div>
              <div className="p-2 rounded-lg">
                  <StyledButton
                    onClick={handleQuizSubmit}
                    backgroundColor={EditMode ? "#F57C00" : "#4caf50"}
                    color="#fff"
                    width="250px"
                    style={{ height: '65px'}}
                  >
                    {EditMode ? "Edit Quiz" : "Create Quiz"}
                  </StyledButton>
                </div>
              <div className="p-2 rounded-lg">
                <StyledButton
                  onClick={handleClick}
                  backgroundColor="#286cff"
                  color="#fff"
                  width="250px"
                  style={{ height: '65px', marginLeft: '0px'  }} // Use inline style for height
                >
                  Create a New Quiz Question
                </StyledButton>
              </div>
            </div>
              <div className="quiz-questions-list-container w-full px-6 py-6" style={{ marginLeft: '200px' }}>
              <main className="my-2">
                                    <p className="form__label">Name of Quiz</p>
                                        <input
                                            type="text"
                                            placeholder="Type The Quiz Name Here"
                                            value={quizName}
                                            onChange={(e) =>
                                                setQuizName(e.target.value)
                                            }
                                            className={`form__border my-2 `}
                                            style={{ width: '1200px' }} 
                                            
                                        />
                                </main>
                <CreateQuizList 
                 selectedQuestions = {selectedQuestions}                
                 /* I did the below as it was saying setselectedQuestions not recognised as a function in the child componenet?*/
                 setSelectedQuestions = {(newValue) => setSelectedQuestions(newValue)}
                 match={match} 
                 allQuizQuestions={allQuizQuestions}    
                 CreateModalOpen={CreateModalOpen} 
                 setCreateModalOpen={setCreateModalOpen} 
                 refetchQuizQuestions={refetchQuizQuestions} 
                 refetchQuizzes={refetchQuizzes} 
                 />
              </div>
          </main>
        </div>
      </div>
    );
};

export default withRouter(CreateQuiz);