import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";

const Dashboard = (props) => {
  const [image, setImage] = useState({});
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(false);

  const { mutate, isLoading: isUpdatingProfile } = useMutation(
    (payload) => createSubscription(payload),
    {
      onError: (error, variables, context) => {
        const message = handleError(error);
        toast.error(message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        toast.success("Subscription created successfully");
        setLoading(false);
      },
    }
  );

  const handleCreate = () => {
    mutate({
      ...subscription,
      recurring: true,
      interval: "month",
      currency: "usd",
    });
  };

  const createSubscription = async (data) => {
    try {
      setLoading(true);
      return post("/subscription/create", data, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };
  return (
    <div className="course__page ">
      <Header />
      <div className="py-24" />
      <main className="boxed__container">
        <main className="w-full md:w-3/5 mx-auto">
          <p>Create New Subscription</p>
          <main className="my-2">
            <p className="form__label">Subscripion Name</p>
            <input
              type="text"
              placeholder="e.g Free Subcription"
              value={subscription.name}
              onChange={(e) =>
                setSubscription({ ...subscription, name: e.target.value })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Subscription Description</p>
            <input
              type="text"
              placeholder="Brief Description"
              value={subscription.description}
              onChange={(e) =>
                setSubscription({
                  ...subscription,
                  description: e.target.value,
                })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Subscripion Amount</p>
            <input
              type="number"
              placeholder="e.g 4000"
              value={subscription.amount}
              onChange={(e) =>
                setSubscription({ ...subscription, amount: Number(e.target.value) })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="flex w-full ">
            <StyledButton
              onClick={(e) => {
                handleCreate();
              }}
              borderRadius="3px"
              width="75px"
              padding="10px 5px"
              backgroundColor="#286cff"
              className="mx-auto"
            >
              {loading || isUpdatingProfile ? <Loader /> : "Save"}
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Dashboard;
