import React, { useState } from "react";
import QuestionModal from "../QuestionModal";
import QuestionPage from "../QuestionPage";
import CreateQuizQuestion from "../../../views/Quiz/CreateQuizQuestion";
import "./../ListQuizQuestions/ListQuizQuestions.scss";



function  CreateQuizList({ 
    selectedQuestions, 
    setSelectedQuestions, 
    match , 
    allQuizQuestions,
    CreateModalOpen, 
    setCreateModalOpen, 
    refetchQuizQuestions,
    refetchQuizzes }) {

    const [PreviewModalOpen, setPreviewModalOpen] = useState(false);
    const [EditModalOpen, setEditModalOpen] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [isLoading, setLoading] = useState(false);

    const handleCheckboxChange = (e, question) => {
        if (e.target.checked) {
            setSelectedQuestions(prev => [...prev, question]);
        } else {
            setSelectedQuestions(prev => prev.filter(q => q._id !== question._id));
        }
    };


    return (
        <div className="w-full flex flex-col items-stretch">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="section-container w-full">
                    {Array.isArray(allQuizQuestions) ? (
                        allQuizQuestions.length > 0 ? (

                            allQuizQuestions.map((question) => (
                                    <div key={question._id} className="flex justify-between items-center mb-4 w-full space-x-4">
                                        <div className="flex items-center space-x-4 flex-1 border-2 border-gray-300 p-2 rounded">
                                        {/* the below code handles question checkboxes  */}
                                                <input
                                                    type="checkbox"
                                                    checked={selectedQuestions.some(q => q._id === question._id)}
                                                    onChange={(e) => handleCheckboxChange(e, question)}
                                                    className="mr-2"
                                                />
                                            <span className="text-lg text-black" style={{ whiteSpace: 'pre-line'}}>{question.question}</span>
                                            {question.image && question.image.trim() !== '' && (
                                                <img src={question.image} alt="Question Image" className="h-auto object-contain" style={{ width: "50%" }} />
                                            )}
                                        </div>
                                        <div className="flex space-x-2">
                                            <button onClick={() => { setCurrentQuestion(question); setEditModalOpen(true); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-yellow-500 hover:bg-yellow-700 rounded-lg focus:outline-none">Edit Question</button>
                                            <button onClick={() => { setCurrentQuestion(question); setPreviewModalOpen(true); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-blue-500 hover:bg-blue-700 rounded-lg focus:outline-none">Preview Question</button>                                                                        
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div>No quiz questions found.</div>
                        )
                    ) : (
                        <div>No quiz questions found.</div>
                    )}

                    <QuestionModal isOpen={EditModalOpen} onClose={() => setEditModalOpen(false)}>
                        <CreateQuizQuestion inputQuizQuestion={currentQuestion} match={match} isEditModalOpen = {setEditModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuestionModal>
                    <QuestionModal isOpen={PreviewModalOpen} onClose={() => setPreviewModalOpen(false)}>
                        <QuestionPage quizQuestion={currentQuestion} />
                    </QuestionModal>
                    <QuestionModal isOpen={CreateModalOpen} onClose={() => setCreateModalOpen(false)}>
                        <CreateQuizQuestion match={match} isCreateModalOpen = {setCreateModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuestionModal>
                </div>
            )}
        </div>
    );
}

export default CreateQuizList;