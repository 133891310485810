import React, { Component, useState, useContext } from "react";
import Header from "../../components/Auth/Header";
import Side from "../../components/Auth/Side";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { StyledProgress } from "../../components/Auth/progress.style";
import login from "../../img/login.png";
import register_mini from "../../img/register-mini.png";
import "./authentication.scss";
import { api } from "../../utils/api";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "react-hook-form";

const Login = (props) => {
  const { setUser, setToken, token } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    handleLogin();
  };
  const [sideNav, setSideNav] = useState({
    name: "Tunde Adebanjo",
    quote:
      "The ease of having access to different subjects at any point in time is all the reason you need to be a part of this community.",
    backgroundImage: login,
    profile: register_mini,
  });
  const [header, setHeader] = useState({
    text: "Another Account?",
    side: "Sign Up",
    route: "/register",
  });
  const { mutate, isLoading } = useMutation((payload) => loginUser(payload), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
      setLoading(false);
    },
    onSuccess: (data, variables, context) => {
      toast.success("Login Successful");
      setLoading(false);
      setToken(data.data.data.user.token);
      setUser(data.data.data.user);
      localStorage.setItem("maths-user", data.data.data.user.token);

      window.location.href = "/courses";
      // props.history.push("/courses");
    },
  });

  const loginUser = async (data) => {
    try {
      setLoading(true);
      return api.post("/auths/admin-login", data);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleLogin = () => {
    mutate({ username, password });
  };
  const handleClick = (route) => {
    props.history.push(route);
  };
  return (
    <main className="auth__Login">
      <div className="authentication min-h-screen flex flex-wrap w-full">
        <main className="w-full hidden xl:block xl:w-1/3">
          <Side data={sideNav} />
        </main>
        <main className="w-full xl:w-2/3">
          <Header data={header} />
          <main className="mt-8 w-4/5 mx-auto xl:px-32">
            <p className="form__header">Welcome back</p>
            <p className="form__subheadline">Please sign in to your account</p>
            <main className="unfilled__progress rounded mt-4">
              <StyledProgress width="100%" />
            </main>

            <form onSubmit={handleSubmit(onSubmit)}>
              <main className="flex flex-wrap mt-8 ">
                <main className="w-full md:w-1/2 my-2 px-2">
                  <p className="form__label">
                    Username<span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    // {...register("username", { required: true })}
                    className={`form__border w-full my-2 ${
                      errors.username ? "red__form__border" : ""
                    }`}
                    placeholder="e.g Ademola"
                    onChange={(e) => setUsername(e.target.value)}
                  />

                  <p className="error__message">
                    {errors.username && "Username is required"}
                  </p>
                </main>

                <main className="w-full md:w-1/2 my-2 px-2">
                  <p className="form__label">
                    Password<span className="text-red-500">*</span>
                  </p>
                  <input
                    type="password"
                    // {...register("password", { required: true, minLength: 6 })}
                    className={`form__border w-full my-2 ${
                      errors.password ? "red__form__border" : ""
                    }`}
                    placeholder="****"
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <p className="error__message">
                    {errors &&
                      errors.password &&
                      errors.password.type === "required" &&
                      "Password is required"}
                    {errors &&
                      errors.password &&
                      errors.password.type === "minLength" &&
                      "Password must be a minimum of 6 characters"}
                  </p>
                </main>

                <main className="flex flex-wrap justify-between w-full my-2 px-2">
                  <p className="form__muted">
                    <input className="pb-2" name="isGoing" type="checkbox" />{" "}
                    <span className="pb-2">Remember me</span>
                  </p>
                  <p className="form__muted">
                    Reset Password
                    <span
                      onClick={() => {
                        handleClick("/reset");
                      }}
                      className="ml-2 blue__text"
                    >
                      Forgot Password?{" "}
                    </span>
                  </p>
                </main>

                <main className="w-full my-8 px-2">
                  <StyledButton
                    type="submit"
                    borderRadius="3px"
                    width="100%"
                    backgroundColor="#286cff"
                    disabled={loading ? true : false}
                  >
                    {loading ? <Loader /> : "Login"}
                  </StyledButton>
                </main>
              </main>
            </form>
          </main>
        </main>
      </div>
    </main>
  );
};
export default Login;
