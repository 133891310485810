import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { put, get, post, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";

const Dashboard = (props) => {
  const [image, setImage] = useState(null);
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);

  const { mutate, isLoading } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        const message = handleError(error);
        toast.error(message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const initialUpload = async ({ name, type }) => {
    try {
      setLoading(true);
      return post(
        `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
        {},
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const onFileChange = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    mutate({ name: temp.name, type: temp.type });
  };

  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            updateCourse({
              title: course.title,
              description: course.description,
              image: url,
              status: "",
            });
          } else {
            toast.error("An error occurred! Could not upload file.");
          }
        }
      };
      xhr.send(image);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const { mutate: updateCourse, isLoading: isUpdatingProfile } = useMutation(
    (payload) => updateCourseDetails(payload),
    {
      onError: (error, variables, context) => {
        const message = handleError(error);
        toast.error(message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        toast.success("Course created successfully");
        props.history.push("/challenge");
        setLoading(false);
      },
    }
  );

  const updateCourseDetails = async (data) => {
    try {
      setLoading(true);
      return put(
        `/challenges/update-course/${props.match.params.id}`,
        data,
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const preUpdate = async (e) => {
    e.preventDefault();
    if (image) {
      onFileChange(e);
    } else {
      updateCourse({ title: course.title, description: course.description });
    }
  };

  const {
    isLoading: loading3,
    error: coursesError,
    data: coursesData,
    refetch: refetchData,
  } = useQuery("fetchCourse", () =>
    get(`/challenges/${props.match.params.id}`, true).then((res) => {
      setCourse(res.data.course);
    })
  );
  return (
    <div className="course__page ">
      <Header />
      <div className="py-24" />
      <main className="boxed__container">
        <main className="w-full md:w-3/5 mx-auto">
          <p>Edit Challenge</p>
          <main className="my-2">
            <p className="form__label">Challenge Title</p>
            <input
              type="text"
              placeholder="e.g Introduction to Mathematics"
              value={course.title}
              onChange={(e) => setCourse({ ...course, title: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Course Description</p>
            <input
              type="text"
              placeholder="What is this course about"
              value={course.description}
              onChange={(e) =>
                setCourse({ ...course, description: e.target.value })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <input
            type="file"
            placeholder="e.g Ademola"
            onChange={(e) => {
              setImage(e);
            }}
            id="file-input"
            className={`form__border w-full my-2 `}
          />

          <main className="flex w-full ">
            <StyledButton
              onClick={(e) => {
                preUpdate(e);
              }}
              borderRadius="3px"
              width="75px"
              padding="10px 5px"
              backgroundColor="#286cff"
              className="mx-auto"
            >
              {loading || isLoading || isUpdatingProfile ? (
                <Loader />
              ) : (
                "Update"
              )}
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Dashboard;
