import "./auth.scss";
import React, { Component } from "react";
import star from "../../img/star.svg";

export default class Side extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <main
        className=""
        style={{ backgroundImage: `url(${data.backgroundImage})` }}
        className=""
      >
        <main className=" min-h-screen flex items-end pb-8 px-16">
          <main className="p-4 mb-8">
            <p className="quote">"{data.quote}"</p>
            <main className="flex items-center my-6">
              <img className="h-16" src={data.profile} />
              <main className="ml-4">
                <p className="quote__name">{data.name}</p>
                {/* <span className="flex">
                  {[...Array(5)].map((index) => (
                    <img key={index} src={star} />
                  ))}
                </span> */}
              </main>
            </main>
          </main>
        </main>
      </main>
    );
  }
}
