import React, { Component, useEffect, useState } from "react";
import Header from "../../../components/Shared/Header";
import "../index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, handleError, patch } from "../../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../../components/Shared/Loader";
import { StyledButton } from "../../../components/Shared/Button/button.style";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "600px",
    padding: "0px",
  },
};

const EditPDF = (props) => {
  const history = useHistory();
  const [image, setPDFFile] = useState({});
  const [pdf, setPDF] = useState({});
  const [loading, setLoading] = useState(false);
  // const [loadingPDFUpload, setLoadingPDFUpload] = useState(false);
  const [imageAddModal, setPDFFileAddModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  // const [pdfUploadModal, setPdfUploadModal] = useState(false);

  const [uploadedPDF, setUploadedPDF] = useState(false);
  

  useEffect(() => {
    get( `/courses/lessons/${props.match.params.lesson}/pdf/${props.match.params.pdfId}`, true).then((res) => {
 
        if(res.data.status == 'success') {
            setPDF(res.data.data);
        }
      })
    }, [])

  const handleClick = (route) => {
    console.log({route})
    history.push(route);
  };


  const { mutate: imageUploadMutation, isLoading: loading5 } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setPDF({ ...pdf, image: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const initialUpload = async ({ name, type }) => {
    try {
      setLoading(true);
      return post(
        `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
        {},
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleCoverImageUpload = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    imageUploadMutation({ name: temp.name, type: temp.type });
  };

  const { mutate: videoUploadMutation, isLoading } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setPDF({ ...pdf, video: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );


  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // setPDFFileAddModal(false);
            setUploadModal(false);
            setLoading(false);
          } else {
            toast.error("An error occurred! Could not upload file.");
          }
        }
      };
      xhr.send(image);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const uploadPDF = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await patch(
        `/courses/lessons/${props.match.params.lesson}/pdf/${props.match.params.pdfId}`,
        {
          ...pdf,
        },
        true
      );
      toast.success("PDF updated successfully");
      setLoading(false);
      return history.push(`/course/${props.match.params.id}/${props.match.params.lesson}/manage-pdf`);
    } catch (e) {
      setLoading(false);
      const message = handleError(e);
      toast.error(message);
      return e;
    }
  };

  const handlePDFUpload = (e, type) => {
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    pdfUploadMutation({ name: temp.name, type: temp.type });
  }

  const { mutate: pdfUploadMutation, loadingPDFUpload } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
          setPDF({ ...pdf, url: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  return (
    <div className="course__page ">
      <Header />


      <Modal
        isOpen={imageAddModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setPDFFileAddModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <main className="h-16 flex justify-end p-2 items-end top__part">
          <main
            onClick={(e) => {
              e.preventDefault();
              setPDFFileAddModal(false);
            }}
            className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
          >
            x
          </main>
        </main>
        {/* <p className="modal__header pt-8 text-center">Upload Cover Image</p> */}

        <form>
          <main className="flex flex-wrap w-full justify-center mt-8 px-4">
            <input
              type="file"
              placeholder="e.g Ademola"
              onChange={(e) => {
                setPDFFile(e.target.files[0]);
              }}
              id="file-input"
              className={`form__border w-full my-2 px-4 `}
            />
            <main className="w-full flex  mt-8 justify-center">
              <StyledButton
                onClick={(e) => {
                  handleCoverImageUpload(e);
                }}
                type="submit"
                weight="normal"
                size="12px"
                padding="10px 10px"
                backgroundColor="#286cff"
              >
                {loading ? <Loader /> : "Upload PDF"}
              </StyledButton>
            </main>
          </main>
        </form>
      </Modal>

      <Modal
        isOpen={uploadModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setUploadModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <main className="h-16 flex justify-end p-2 items-end top__part">
          <main
            onClick={(e) => {
              e.preventDefault();
              setUploadModal(false);
            }}
            className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
          >
            x
          </main>
        </main>
        <p className="modal__header pt-8 text-center">Upload PDF File</p>

        <form>
          <main className="flex flex-wrap w-full justify-center mt-8 px-4">
            <input
              type="file"
              placeholder="e.g Ademola"
              accept=".pdf"
              onChange={(e) => {
                setPDFFile(e.target.files[0]);
              }}
              id="file-input"
              className={`form__border w-full my-2 px-4 `}
            />
            <main className="w-full flex  mt-8 justify-center">
              <StyledButton
                onClick={(e) => {
                  handlePDFUpload(e);
                }}
                type="submit"
                weight="normal"
                size="12px"
                padding="10px 10px"
                backgroundColor="#286cff"
              >
                {loading ? <Loader /> : "Upload"}
              </StyledButton>
            </main>
          </main>
        </form>
      </Modal>




      <div className="py-24" />
      <main className="boxed__container">


          <main className=" my-2 p-2 rounded-lg">
            <StyledButton
              onClick={() => {
                handleClick(`/course/${props.match.params.id}/${props.match.params.lesson}/manage-pdf/`);
              }}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
            >
              Go back
            </StyledButton>
          </main>

        <main className="w-full md:w-3/5 mx-auto">
          <p>Edit PDF</p>
          <main className="my-2">
            <p className="form__label">Name</p>
            <input
              type="text"
              placeholder="e.g Introduction to Mathematics"
              value={pdf.title}
              onChange={(e) => setPDF({ ...pdf, title: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>



          <main className="my-2">
            <span className="form__label">PDF URL</span>{" "}
            <small
              onClick={(e) => {
                e.preventDefault();
                setUploadModal(true);
              }}
            >
               <strong>(Upload PDF file Instead)</strong>
            </small>
            <input
              type="text"
              value={pdf.url}
              onChange={(e) => setPDF({ ...pdf, url: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <span className="form__label">PDF Type</span>{" "}
            <select 
              value={pdf.type}
              onChange={(e) => setPDF({ ...pdf, type: e.target.value })}
              className={`form__border w-full my-2 `}>
              <option value="">Select an option</option>
              <option>Question</option>
              <option>Answer</option>
              <option>Other</option>
            </select>
          </main>

          <main className="my-2">
            <input
              type="checkbox"
              value={pdf.allow_download}
              onChange={(e) => setPDF({ ...pdf, allow_download: e.target.checked })}
            />
            <span className="ml-1 form__label">This PDF downloadable</span>{" "}
          </main>

          <main className="flex w-full ">
            <StyledButton
              onClick={(e) => {
                uploadPDF(e);
              }}
              borderRadius="3px"
              width="75px"
              padding="10px 5px"
              backgroundColor="#286cff"
              className="mx-auto"
            >
              {loading || isLoading ? <Loader /> : "Save"}
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default EditPDF;
