import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, del, handleError } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import Loader from "../../components/Shared/Loader";
import CreateCategory from "./createCategory";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    padding: "0px",
  },
};

const Dashboard = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [courses, setCourses] = useState([]);
  const [loading2, setLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();


  const { mutate, isLoading } = useMutation((id) => deleteCourse(id), {
    onError: (error, variables, context) => {
      const message = handleError(error);
      toast.error(message);
      setLoading(false);
    },
    onSuccess: (data, variables, context) => {
      toast.success("Course deleted Successfully");
      refetchData();
      setLoading(false);
    },
  });

  const deleteCourse = async (id) => {
    try {
      setLoading(true);
      return del(`exams/category/${id}`, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleDelete = (course) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this exam category?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            mutate(course.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const {
    isLoading: loading,
    error: coursesError,
    data: coursesData,
    refetch: refetchData,
  } = useQuery("fetchCourses", () =>
    get("/exams/category/fetch-all", true).then((res) => {
      setCourses(res.data.data);
    })
  );

  const handleClick = (route) => {
    history.push(route);
  };





  return (
    <div className="dashboard__page ">
      <Header />

      <div className="py-8" />
      <main className="py-16 w-full ">


        <Modal
          isOpen={categoryModalIsOpen}
          onRequestClose={() => setCategoryModalIsOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
        // className="my__modal"
        >
          <main className="h-32 flex justify-end p-8 items-end top__part">
            <main
              onClick={() => setCategoryModalIsOpen(false)}
              className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
            >
              x
            </main>
          </main>

          <CreateCategory onCompleted={() => setCategoryModalIsOpen(false)} />
        </Modal>



        <main className=" my-16 boxed__container top__wrapper block md:flex justify-between rounded-lg items-center">
          <main className="py-6">
            <p className="name capitalize">Welcome, {user?.username}.</p>
            <p className="others">Please find below all created exams categories</p>
          </main>

          <main className=" my-2 p-2 rounded-lg">
            {/* <StyledButton
              onClick={() => setCategoryModalIsOpen(true)}
              weight="normal"
              size="12px"
              padding="10px 5px"
              color="#286cff"
              backgroundColor="#fff"
              border="border"
              borderRadius="5px"
            >
              Create Category
            </StyledButton> */}
            <StyledButton
              onClick={() => setCategoryModalIsOpen(true)}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
            >
              Create a New Category
            </StyledButton>
          </main>
        </main>
        <div className="my-12" />

        <main className="shadow rounded-xl mx-4 md:ml-4 md:mr-12">
          {loading ? <Skeleton className="mt-4" count={5} /> : ""}
        </main>

        {/* Show Courses if not loading, not fetching Subscripion and has subscription */}
        <main className="  md:ml-4 md:mr-12">
          {!loading ? (
            <main className="shadow mx-4 rounded-xl my-16 flex flex-wrap boxed__container top__wrapper ">
              {courses &&
                courses.map((course, index) => (
                  <main key={index} className="w-full  md:w-1/4 p-4">
                    <div
                      onClick={() => {
                        handleClick(`/exam/${course.id}`);
                      }}
                      style={{
                        backgroundColor: '#000',
                        backgroundImage: `url(${course.cover_image_url})`,
                        cursor: "pointer",
                      }}
                      className=" h-64 flex bg-grey-900 items-end w-ful text-white p-6 mx-2 rounded-2xl"
                    >
                      <main className="flex items-center  justify-between w-full">
                        <main>
                          <p className="card__title">{course.name}</p>
                        </main>
                        {/* <p className="card__duration">9</p> */}
                      </main>
                    </div>
                    <main className="flex justify-end mr-4 card__actions cursor-pointer">
                      <p
                        onClick={() => {
                          history.push(`/exam/${course.id}`);
                        }}
                        className="m-1  text-blue-500"
                      >
                        View Exams
                      </p>
                      <p
                        onClick={() => {
                          history.push(`/category/${course.id}/update`);
                        }}
                        className="m-1  text-blue-500"
                      >
                        Edit
                      </p>
                      <p
                        className="m-1 text-red-500 cursor-pointer"
                        onClick={() => {
                          handleDelete(course);
                        }}
                      >
                        Delete
                      </p>
                    </main>
                  </main>
                ))}
            </main>
          ) : (
            ""
          )}
        </main>
      </main>
    </div>
  );
};

export default withRouter(Dashboard);
