import React, { Component } from "react";
import Header from "../../components/HomePage/Header";
import Landing from "../../components/HomePage/Landing";
import PopularTopics from "../../components/HomePage/PopularTopics";
import Benefits from "../../components/HomePage/Benefits";
import Pricing from "../../components/HomePage/Pricing";
import BeforeFooter from "../../components/HomePage/BeforeFooter";
import Footer from "../../components/HomePage/Footer";
import about from "../../img/about.png";
import user_1 from "../../img/user_1.png";
import {
  PageHeaderContainer,
  PageHeaderText,
} from "../../components/Shared/PageHeader/pageheader.style";
import "./about.scss";

export default class Home extends Component {
  render() {
    return (
      <div className="about ">
        <Header />
        <div className="pt-32" />
        <main className="boxed__container">
          <main className="mx-auto flex justify-center">
            <PageHeaderContainer className="">
              <PageHeaderText>Our Mission</PageHeaderText>
            </PageHeaderContainer>
          </main>
          <main className="flex justify-center">
            <main className="w-full md:w-1/2 ">
              <p className="page__header my-4">Making Learning Fun for Kids</p>
              <p className="page__content my-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </main>
          </main>
          <img src={about} />
          <p className="page__content mx-8 my-16">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra
            magna ac placerat vestibulum lectus mauris. Lobortis feugiat vivamus
            at augue eget arcu dictum varius duis. Scelerisque eleifend donec
            pretium vulputate sapien nec sagittis aliquam malesuada. Faucibus a
            pellentesque sit amet porttitor. Diam quis enim lobortis scelerisque
            fermentum dui. Ut consequat semper viverra nam libero. Volutpat
            commodo sed egestas egestas fringilla phasellus faucibus scelerisque
            eleifend. Pulvinar neque laoreet suspendisse interdum consectetur
            libero. Ultricies integer quis auctor elit sed vulputate. Id velit
            ut tortor pretium viverra. Ipsum dolor sit amet consectetur
            adipiscing. At tellus at urna condimentum. Aenean euismod elementum
            nisi quis eleifend quam adipiscing vitae proin. Vel pharetra vel
            turpis nunc. Lorem dolor sed viverra ipsum nunc.
          </p>

          <main className="flex justify-center my-16">
            <main className="w-full md:w-1/2 ">
              <p className="page__header my-4">Meet the Team</p>
              <p className="page__content my-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </main>
          </main>

          <main className="flex flex-wrap mb-24 ">
            {[...Array(6)].map((index) => (
              <div key={index} className="w-full md:w-1/3 text-center p-2">
                <main className="my-2">
                  <img className="mx-auto h-48" src={user_1} />
                  <p className="text-center about__name mt-4">Yomi Omotosho</p>
                  <p className="text-center about__post">Lorum Ipsum</p>
                </main>
              </div>
            ))}
          </main>
        </main>
        <BeforeFooter />
        <Footer />
      </div>
    );
  }
}
