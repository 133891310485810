import "./auth.scss";
import { Link, NavLink } from "react-router-dom";
import React, { Component, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

const Header = (props) => {
  const { token } = useContext(AuthContext);
  const { data } = props;
  return (
    <main className="auth__header">
      <main className="flex w-full md:pr-32 p-8 items-center justify-end">
        <p className="text mr-4">{data.side}</p>
        <NavLink className="nav__button" to={data.route}>
          {data.text}
        </NavLink>
      </main>
    </main>
  );
};
export default Header;
