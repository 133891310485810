import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import SideMenu from "../../components/Course/SideMenu";
import Quiz from "../../components/Course/Quiz";
import Document from "../../components/Course/Document";
import ReactPlayer from "react-player";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { RWebShare } from "react-web-share";

const Dashboard = (props) => {
  const [isAssessment, setIsAssessment] = useState(false);
  const [course, setCourse] = useState([]);
  const [activeCourseIndex, setActiveCourseindex] = useState(0);
  const [activeCourse, setActiveCourse] = useState({});
  const [hasFetched, setHasFetched] = useState(false);
  const [lessonType, setLessonType] = useState("video");

  const {
    isLoading: loadingData,
    error,
    data,
    refetch,
  } = useQuery("courseData", () =>
    get(`courses/${props.match.params.courseId}`, true).then((res) => {
      setCourse(res.data.course);
      if (Object.keys(activeCourse).length === 0) {
        setActiveCourse(res.data.course.lessons[0]);
        setHasFetched(false);
      }
    })
  );

  const handleLessonChange = (lesson, type, index) => {
    setActiveCourse(lesson);
    setLessonType(type);
    if (type === "assessment") {
      setIsAssessment(true);
    } else {
      setIsAssessment(false);
    }
  };

  return (
    <div className="course__page ">
      <Header />
      <div className="py-8" />
      {loadingData ? (
        <main className="mt-8">
          <Skeleton count={5} />
        </main>
      ) : (
        <main className="py-16 w-full ">
          <main className=" my-16 flex flex-wrap boxed__container top__wrapper ">
            <div className="flex w-full bg-black p-6 justify-between header__text">
              <main>{course.title}</main>
              <main className="flex">
                <p className="mx-2">Your Progress</p>
                <RWebShare
                  data={{
                    text: "Hi, I am currently taking this course on Mathematica, I think you would like it also",
                    url: window.location.href,
                    title: "Share Course",
                  }}
                >
                  <p className="cursor-pointer">Share</p>
                </RWebShare>
              </main>
            </div>
            <main className="w-full md:w-1/5 border">
              <p className="cursor-pointer" className="side__text my-3 mx-8">
                Courses Content
              </p>
              <SideMenu
                activeCourseIndex={activeCourseIndex}
                lessons={course.lessons}
                route="courses"
                onClickItem={(lesson, type) => {
                  handleLessonChange(lesson, type);
                }}
              />
            </main>
            <main className="w-full md:w-4/5 border">
              <main>
                {lessonType === "assessment" ? (
                  <Quiz route="courses" assessment={activeCourse} />
                ) : lessonType === "document" ? (
                  <Document route="document" document={activeCourse} />
                ) : (
                  <ReactPlayer
                    className="w-full"
                    width="100%"
                    height="600px"
                    controls
                    url={activeCourse?.video}
                  />
                )}
              </main>
            </main>
          </main>
        </main>
      )}
    </div>
  );
};

export default Dashboard;
