import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import TrendCard from "../../components/Forum/Trend";
import CommentCard from "../../components/Forum/Comment";
import { StyledButton } from "../../components/Shared/Button/button.style";
import "./index.scss";
import sam from "../../img/sam.png";
import BackButton from "../../components/Forum/Back";
import { api, get, post } from "../../utils/api";
import { useMutation, useQueries, useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { format } from "date-fns";
import * as moment from "moment";
import { toast } from "react-toastify";

const Post = (props) => {
  // const { user, token } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const {
    isLoading: isLoadingUser,
    error: statusError,
    data: statusData,
  } = useQuery("fetchSingleUserDetails", () =>
    get(`/users/profile/${props.match.params.userId}`, true).then((res) => {
      setLoading(false);
      setUser(res.data.user);
    })
  );

  const { mutate, isLoading: createLoader } = useMutation(() => startChat(), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
      setLoading(false);
    },
    onSuccess: (data, variables, context) => {
      props.history.push("/messages");
    },
  });

  const startChat = async () => {
    try {
      setLoading(true);
      const res = await get(
        `/chats/start-channel/${props.match.params.userId}`,
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  return (
    <div className="user__page ">
      <Header />

      <main className="py-16 w-full ">
        <main className="boxed__container w-full md:w-1/2 ">
          <main className="limit__width">
            {isLoadingUser ? (
              <main className="mt-8">
                <Skeleton count={5} />
              </main>
            ) : (
              <main className="pt-8">
                <BackButton className="pt-8" />
                <main className="flex justify-center mt-12">
                  {user && user.profile_image ? (
                    <img
                      src={user.profile_image}
                      alt="User Profile Picture"
                      className="rounded-full mx-auto h-24 w-24 "
                    />
                  ) : (
                    <p className="text-center">
                      <i className="fas fa-5x text-white fa-user-circle text-center mx-auto"></i>
                    </p>
                  )}
                </main>
                <p className="user__header pt-8 text-left capitalize">
                  {user.fullname}
                </p>
                <p className="user__subheader text-center py-2">
                  {user.username}
                </p>
                <main className="flex justify-between mini my-4">
                  <p>
                    Location{" "}
                    <span className="ml-2 blue__text">{user.location}</span>
                  </p>
                  <p>
                    Date Registered
                    <span className="ml-2 blue__text">
                      {moment(user.createdAt).format("Do MMMM YYYY")}
                    </span>
                  </p>
                </main>
                <main className="flex justify-center my-4">
                  <StyledButton
                    weight="normal"
                    size="12px"
                    width="200px"
                    padding="15px 5px"
                    backgroundColor="#286cff"
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Send Private Message
                  </StyledButton>
                </main>
              </main>
            )}
          </main>
        </main>
      </main>
    </div>
  );
};

export default Post;
