import React from 'react';
import ReactDOM from 'react-dom';
import "./QuestionModal.css";

const QuestionModal = ({ isOpen, children, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="question-modal-overlay">
      <div className="question-modal-content">
        <button onClick={onClose} className="question-modal-close-button text-black">Close Question Preview</button>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default QuestionModal;