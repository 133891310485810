import "./landing.scss";
import React, { Component, useState } from "react";
import { StyledButton } from "../../Shared/Button/button.style";
import background from "../../../img/landing.png";
import quality_content from "../../../img/quality_content.svg";
import forum from "../../../img/forum.svg";
import access_anywhere from "../../../img/access_anywhere.svg";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

const Home = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const token = localStorage.getItem("maths-user");

  const [items, setItems] = useState([
    {
      img: quality_content,
      title: "Quality Content",
      content: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
      img: access_anywhere,
      title: "Access Anywhere",
      content: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
      img: forum,
      title: "Forum",
      content: "Lorem ipsum dolor sit amet, consectetur.",
    },
  ]);
  return (
    <div className="landing">
      <div
        className="wrapper flex w-full items-center md:items-end md:pb-32 justify-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <main className="text-center mt-24 md:mt-24 md:mx-8">
          <p className="main__text">Make Learning Mathematics Fun Again!</p>
          <p className="mini__text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </p>

          <main className="my-12 md:mt-12">
            {token ? (
              <StyledButton
                onClick={() => {
                  handleClick("/dashboard");
                }}
                backgroundColor="#286cff"
                width="200px"
              >
                Go to Dashboard
              </StyledButton>
            ) : (
              <StyledButton
                onClick={() => {
                  handleClick("/login");
                }}
                backgroundColor="#286cff"
              >
                Get Started
              </StyledButton>
            )}
          </main>
        </main>
      </div>
      <div className="flex flex-wrap w-full boxed__container ">
        {items.map((item, index) => (
          <main key={index} className="w-full md:w-1/3 px-2 ">
            <div className="rounded-lg shadow-lg mx-2 mt-4 md:-mt-16 bg-white flex w-full px-6 py-12">
              <img className="h-20" src={item.img} />
              <div className="ml-8">
                <p className="card__title text-black">{item.title}</p>
                <p className="card__body mt-2">{item.content}</p>
              </div>
            </div>
          </main>
        ))}
      </div>
    </div>
  );
};

export default withRouter(Home);
