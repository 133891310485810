import React, { useState, useEffect } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { get } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { StyledButton } from "../../components/Shared/Button/button.style";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import QuestionBankList from "../../components/Quiz/QuestionBankList";
import DisplayQuizList from "../../components/Quiz/DisplayQuizList";
import QuizModal from "../../components/Quiz/QuizModal";
import CreateQuiz from "./CreateQuiz";

const QuizQuestions = (props) => {

const [isLoading, setIsLoading] = useState(true);
const courseId = props.match.params.id;
const lessonId = props.match.params.lesson;
const [title, setTitle] = useState('');
const [CreateModalOpen, setCreateModalOpen] = useState(false);
const [Quizzes, setQuizzes] = useState([]);
const [quizIdsBeingUsed, setQuizIdsBeingUsed] = useState([]);
const [quizQuestions, setQuizQuestions] = useState([]);
const [createQuizModalOpen, setCreateQuizModalOpen] = useState(false);

  // Fetch lesson data on mount
  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const { data } = await get(`/courses/lessons/${lessonId}`, true);
        setTitle(data.course.title);
        setIsLoading(false);
      } catch (error) {
        toast.error("Failed to fetch lesson data");
        setIsLoading(false);
      }
    };

    fetchLesson();
  }, [lessonId]);

  const fetchQuizzes = async () => {
    try {
      const res = await get(`/quiz/course/${courseId}/lesson/${lessonId}`, true);
      if (res.data) {
        setQuizzes(res.data);
        
        // Extract question IDs
        const allQuestionIds = res.data.flatMap(quiz => quiz.questions.map(question => question._id));
        setQuizIdsBeingUsed(allQuestionIds);
      } else {
        setQuizzes([]);
        setQuizIdsBeingUsed([]);
      }
    } catch (error) {
      toast.error("Failed to fetch quiz questions");
      setQuizzes([]);
      setQuizIdsBeingUsed([]);
    }
  };

  const fetchQuizQuestions = async () => {
    try {
      
      const res = await get(`/quiz/quiz-questions/${courseId}/${lessonId}/all`, true);
      if (res.data) {
        setQuizQuestions(res.data);
      } else {
        setQuizQuestions([]);
      }
    } catch (error) {
      toast.error("Failed to fetch quiz questions");
    }
  };

//-------------------------------------------------------------------------------------------
// Pretty sure no need for these as lessonId can't change

      // Fetch quizzes
      useEffect(() => {  
        fetchQuizzes();
      }, [lessonId]);

    // Fetch quiz questions
    useEffect(() => {  
      fetchQuizQuestions();
    }, [lessonId]);

//-------------------------------------------------------------------------------------------

  const handleCreateQuiz = () => {
    setCreateQuizModalOpen(true);
};

  const handleClick = () => {
      setCreateModalOpen(true);
  }

  if (isLoading) return <Skeleton height={100} />;


return (
  <div className="QuizQuestions__page">
    <Header />
    <div className="w-full px-0">
      <main className="py-16 w-full">
        <div className="top__wrapper block md:flex justify-between rounded-lg items-center my-16">
          <div className="py-6">
            <p className="name capitalize" style={{ marginLeft: '200px' }}>{title} - Quiz</p>
          </div>
          <div className="p-2 rounded-lg">
            <StyledButton
              onClick={handleCreateQuiz}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
              style={{ height: '65px', marginLeft: '0px'  }} // Use inline style for height
            >
              Create a New Quiz 
            </StyledButton>
          </div>
          <div className="p-2 rounded-lg">
            <StyledButton
              onClick={handleClick}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
              style={{ height: '65px', marginLeft: '0px'  }} // Use inline style for height
            >
              Create a New Quiz Question
            </StyledButton>
          </div>
        </div>
        {
            Quizzes.length > 0 ? ( 
              Quizzes.map(quiz => (
                <div className="quiz-questions-list-container w-full px-6 py-6" style={{ marginLeft: '200px' }}>
                  <DisplayQuizList  quiz = {quiz} allQuizQuestions = {quizQuestions} match={props.match} CreateModalOpen={CreateModalOpen} setCreateModalOpen={setCreateModalOpen} refetchQuizQuestions={fetchQuizQuestions} refetchQuizzes={fetchQuizzes} />
                </div>
              ))
            ) :
            null
          }
          <div className="quiz-questions-list-container w-full px-6 py-6" style={{ marginLeft: '200px' }}>
            <QuestionBankList quizIdsBeingUsed = {quizIdsBeingUsed}quizQuestions={quizQuestions} match={props.match} CreateModalOpen={CreateModalOpen} setCreateModalOpen={setCreateModalOpen} refetchQuizQuestions={fetchQuizQuestions} refetchQuizzes={fetchQuizzes}/>
          </div>
          <QuizModal isOpen={createQuizModalOpen} onClose={() => setCreateQuizModalOpen(false)}>
                        <CreateQuiz EditMode = {false} isQuizModalOpen={setCreateQuizModalOpen} match={props.match}  allQuizQuestions = {quizQuestions} refetchQuizQuestions={fetchQuizQuestions} refetchQuizzes={fetchQuizzes} />
          </QuizModal>
      </main>
    </div>
  </div>
);
};


export default withRouter(QuizQuestions);