import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "600px",
    padding: "0px",
  },
};

const CreateLesson = (props) => {
  const history = useHistory();
  const [image, setImage] = useState({});
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageAddModal, setImageAddModal] = useState(false);
  const [videoAddModal, setVideoAddModal] = useState(false);

  const {
    isLoading: fetchLoading,
    error: coursesError,
    data: coursesData,
    refetch: refetchData,
  } = useQuery("fetchSingleLessonDetails", () =>
    get(`/challenges/lessons/${props.match.params.lessonId}`, true).then(
      (res) => {
        setCourse(res.data.course);
      }
    )
  );

  const { mutate: imageUploadMutation, isLoading: loading5 } = useMutation(
    (payload) => initialImageUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setCourse({ ...course, image: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const initialImageUpload = async ({ name, type }) => {
    try {
      setLoading(true);
      return post(
        `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
        {},
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleCoverImageUpload = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    imageUploadMutation({ name: temp.name, type: temp.type });
  };

  const { mutate: videoUploadMutation, isLoading } = useMutation(
    (payload) => initialImageUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setCourse({ ...course, video: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const handleVideoUpload = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    videoUploadMutation({ name: temp.name, type: temp.type });
  };

  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setImageAddModal(false);
            setVideoAddModal(false);
            setLoading(false);
          } else {
            toast.error("An error occurred! Could not upload file.");
          }
        }
      };
      xhr.send(image);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const createCourse = async (e) => {
    e.preventDefault();
    let tags = null;
    try {
      tags = course.tags.split(",");
    } catch (e) {
      tags = course.tags;
    }
    try {
      setLoading(true);
      await put(
        `/challenges/lessons/update-lesson`,
        {
          ...course,
          course: props.match.params.id,
          isActive: true,
          tags,
          duration: parseInt(course.duration),
          id: props.match.params.lessonId,
          // assessment: {
          //   assessment_questions: [],
          // },
        },
        true
      );
      toast.success("Lesson updated successfully");
      return history.goBack();
    } catch (e) {
      setLoading(false);
      const message = handleError(e);
      toast.error(message);
      return e;
    }
  };
  return (
    <div className="course__page ">
      <Header />

      <Modal
        isOpen={imageAddModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setImageAddModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <main className="h-16 flex justify-end p-2 items-end top__part">
          <main
            onClick={(e) => {
              e.preventDefault();
              setImageAddModal(false);
            }}
            className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
          >
            x
          </main>
        </main>
        <p className="modal__header pt-8 text-center">Upload Cover Image</p>

        <form>
          <main className="flex flex-wrap w-full justify-center mt-8 px-4">
            <input
              type="file"
              placeholder="e.g Ademola"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              id="file-input"
              className={`form__border w-full my-2 px-4 `}
            />
            <main className="w-full flex  mt-8 justify-center">
              <StyledButton
                onClick={(e) => {
                  handleCoverImageUpload(e);
                }}
                type="submit"
                weight="normal"
                size="12px"
                padding="10px 10px"
                backgroundColor="#286cff"
              >
                {loading ? <Loader /> : "Upload"}
              </StyledButton>
            </main>
          </main>
        </form>
      </Modal>

      <Modal
        isOpen={videoAddModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setVideoAddModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <main className="h-16 flex justify-end p-2 items-end top__part">
          <main
            onClick={(e) => {
              e.preventDefault();
              setVideoAddModal(false);
            }}
            className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
          >
            x
          </main>
        </main>
        <p className="modal__header pt-8 text-center">Upload Video</p>

        <form>
          <main className="flex flex-wrap w-full justify-center mt-8 px-4">
            <input
              type="file"
              placeholder="e.g Ademola"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              id="file-input"
              className={`form__border w-full my-2 px-4 `}
            />
            <main className="w-full flex  mt-8 justify-center">
              <StyledButton
                onClick={(e) => {
                  handleVideoUpload(e);
                }}
                type="submit"
                weight="normal"
                size="12px"
                padding="10px 10px"
                backgroundColor="#286cff"
              >
                {loading ? <Loader /> : "Upload"}
              </StyledButton>
            </main>
          </main>
        </form>
      </Modal>
      <div className="py-24" />
      <main className="boxed__container">
        <main className="w-full md:w-3/5 mx-auto">
          <p>Edit Lesson</p>
          <main className="my-2">
            <p className="form__label">Lesson Title</p>
            <input
              type="text"
              placeholder="e.g Introduction to Mathematics"
              value={course.title}
              onChange={(e) => setCourse({ ...course, title: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Lesson Description</p>
            <input
              type="text"
              placeholder="What is this lesson about"
              value={course.description}
              onChange={(e) =>
                setCourse({ ...course, description: e.target.value })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Lesson Duration</p>
            <input
              type="number"
              placeholder="What is length of the lesson"
              value={course.duration}
              onChange={(e) =>
                setCourse({ ...course, duration: e.target.value })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Lesson Tags</p>
            <input
              type="text"
              placeholder="tags that explain the lesson seperated by commas"
              value={course.tags}
              onChange={(e) => setCourse({ ...course, tags: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <span className="form__label">Cover Image</span>{" "}
            <small
              onClick={(e) => {
                e.preventDefault();
                setImageAddModal(true);
              }}
            >
               <strong>(Upload document instead)</strong>

            </small>
            <input
              type="text"
              placeholder="What is this course about"
              value={course.image}
              onChange={(e) => setCourse({ ...course, image: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <span className="form__label">Video</span>{" "}
            <small
              onClick={(e) => {
                e.preventDefault();
                setVideoAddModal(true);
              }}
            >
               <strong>(Upload document instead)</strong>

            </small>
            <input
              type="text"
              placeholder="What is this course about"
              value={course.video}
              onChange={(e) => setCourse({ ...course, video: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="flex w-full ">
            <StyledButton
              onClick={(e) => {
                createCourse(e);
              }}
              borderRadius="3px"
              width="75px"
              padding="10px 5px"
              backgroundColor="#286cff"
              className="mx-auto"
            >
              {loading || isLoading ? <Loader /> : "Update"}
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default CreateLesson;
