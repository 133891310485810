import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";

const Dashboard = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);

  const {
    isLoading: loading,
    error: challengesError,
    data: challengesData,
  } = useQuery("fetchSubscriptions", () =>
    get("/subscription/all", true).then((res) => {
      setSubscriptions(res.data.products);
    })
  );
  const handleClick = (route) => {
    history.push(route);
  };

  return (
    <div className="subscription__page ">
      <Header />

      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 boxed__container top__wrapper block md:flex justify-between rounded-lg items-center">
          <main className="py-6">
            <p className="name capitalize">Welcome, {user.username}.</p>
            <p className="others">
              Please find below subscriptions you have created
            </p>
          </main>
          <main>
            <StyledButton
              onClick={() => {
                handleClick("/subscriptions/create");
              }}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
            >
              Create New Subscription
            </StyledButton>
          </main>
        </main>
        <div className="my-12" />

        <main className="shadow rounded-xl mx-4 md:ml-4 md:mr-12">
          {loading ? <Skeleton className="mt-4" count={5} /> : ""}
        </main>

        {/* Show challenges if not loading, not fetching Subscripion and has subscription */}
        <main className="  md:ml-4 md:mr-12">
          {!loading ? (
            <main className="shadow mx-4 rounded-xl my-16 flex flex-wrap boxed__container top__wrapper ">
              {subscriptions &&
                subscriptions.map((subscription, index) => (
                  <main key={index} className="w-full md:w-1/4 p-4">
                    <main className=" bg-gray-300 h-64 flex items-end w-ful text-black p-6 mx-2 rounded-2xl">
                      <main className="flex items-center justify-between w-full">
                        <main>
                          <p className="subscription__title">
                            {subscription.name}
                          </p>
                          <p className="subscription__lessons text-black">
                            {subscription.description}
                          </p>
                        </main>
                      </main>
                      <p className="subscription__description">
                        ${subscription.amount}
                      </p>
                    </main>
                  </main>
                ))}
            </main>
          ) : (
            ""
          )}
        </main>
      </main>
    </div>
  );
};

export default withRouter(Dashboard);
