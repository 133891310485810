import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "600px",
    padding: "0px",
  },
};

const CreateLesson = (props) => {
  const history = useHistory();
  const [image, setImage] = useState({});
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageAddModal, setImageAddModal] = useState(false);
  const [pdfAddModal, setpdfAddModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const {
    data: usersData,
    isLoading: loadingCategories,
    refetch: refetchCatgories,
  } = useQuery("allExamCategoryData", () =>
    get(`exams/category/fetch-all`, true).then((res) => {
      setCategories(res.data.data);
    })
  );

  const {
    isLoading: examLoading,
    error: examError,
    data: examData,
    refetch: refetchExam,
  } = useQuery("fetchSingleExamDetails", () =>
    get(`/exams/${props.match.params.examId}`, true).then((res) => {
      setCourse(res.data.data);
      setCourse({ ...course, category: course.category.id });
    })
  );

  const { mutate: imageUploadMutation, isLoading: loading5 } = useMutation(
    (payload) => initialImageUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setCourse({ ...course, image: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const initialImageUpload = async ({ name, type }) => {
    try {
      setLoading(true);
      return post(
        `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
        {},
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleCoverImageUpload = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    imageUploadMutation({ name: temp.name, type: temp.type });
  };

  const { mutate: videoUploadMutation, isLoading } = useMutation(
    (payload) => initialImageUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setCourse({ ...course, pdf: data.data.data.url });
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const handleVideoUpload = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    videoUploadMutation({ name: temp.name, type: temp.type });
  };

  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setImageAddModal(false);
            setpdfAddModal(false);
            setLoading(false);
          } else {
            toast.error("An error occurred! Could not upload file.");
          }
        }
      };
      xhr.send(image);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const createCourse = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await put(
        `/exams/${props.match.params.examId}/update`,
        {
          ...course,
        },
        true
      );
      toast.success("Exam updated successfully");
      return history.push(`/exams`);
    } catch (e) {
      setLoading(false);
      const message = handleError(e);
      toast.error(message);
      return e;
    }
  };
  return (
    <div className="course__page ">
      <Header />

      <Modal
        isOpen={imageAddModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setImageAddModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <main className="h-16 flex justify-end p-2 items-end top__part">
          <main
            onClick={(e) => {
              e.preventDefault();
              setImageAddModal(false);
            }}
            className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
          >
            x
          </main>
        </main>
        <p className="modal__header pt-8 text-center">Upload Cover Image</p>

        <form>
          <main className="flex flex-wrap w-full justify-center mt-8 px-4">
            <input
              type="file"
              placeholder="e.g Ademola"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              id="file-input"
              className={`form__border w-full my-2 px-4 `}
            />
            <main className="w-full flex  mt-8 justify-center">
              <StyledButton
                onClick={(e) => {
                  handleCoverImageUpload(e);
                }}
                type="submit"
                weight="normal"
                size="12px"
                padding="10px 10px"
                backgroundColor="#286cff"
              >
                {loading ? <Loader /> : "Upload"}
              </StyledButton>
            </main>
          </main>
        </form>
      </Modal>

      <Modal
        isOpen={pdfAddModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setpdfAddModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <main className="h-16 flex justify-end p-2 items-end top__part">
          <main
            onClick={(e) => {
              e.preventDefault();
              setpdfAddModal(false);
            }}
            className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
          >
            x
          </main>
        </main>
        <p className="modal__header pt-8 text-center">Upload Video</p>

        <form>
          <main className="flex flex-wrap w-full justify-center mt-8 px-4">
            <input
              type="file"
              placeholder="e.g Ademola"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              id="file-input"
              className={`form__border w-full my-2 px-4 `}
            />
            <main className="w-full flex  mt-8 justify-center">
              <StyledButton
                onClick={(e) => {
                  handleVideoUpload(e);
                }}
                type="submit"
                weight="normal"
                size="12px"
                padding="10px 10px"
                backgroundColor="#286cff"
              >
                {loading ? <Loader /> : "Upload"}
              </StyledButton>
            </main>
          </main>
        </form>
      </Modal>
      <div className="py-24" />
      <main className="boxed__container">
        <main className="w-full md:w-3/5 mx-auto">
          <p>Create New Exam</p>
          <main className="my-2">
            <p className="form__label">Exam Name</p>
            <input
              type="text"
              placeholder="e.g Introduction to Mathematics"
              value={course.name}
              onChange={(e) => setCourse({ ...course, name: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <span className="form__label">Cover Image</span>{" "}
            <small
              onClick={(e) => {
                e.preventDefault();
                setImageAddModal(true);
              }}
            >
               <strong>(Upload document instead)</strong>

            </small>
            <input
              type="text"
              placeholder="What is this course about"
              value={course.image}
              onChange={(e) => setCourse({ ...course, image: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <span className="form__label">PDF</span>{" "}
            <small
              onClick={(e) => {
                e.preventDefault();
                setpdfAddModal(true);
              }}
            >
              Upload PDF Instead
            </small>
            <input
              type="text"
              placeholder="What is this course about"
              value={course.pdf}
              onChange={(e) => setCourse({ ...course, pdf: e.target.value })}
              className={`form__border w-full my-2 `}
            />
          </main>

          <div className="w-full flex justify-center my-4">
            <main className="w-full mx-auto ">
              <select
                onChange={(e) =>
                  setCourse({ ...course, category: e.target.value })
                }
                value={course.category}
                className={`modal__form__border w-full my-2  w-full my-2`}
              >
                <option>Select a category</option>
                {categories.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </main>
          </div>

          <main className="flex w-full ">
            <StyledButton
              onClick={(e) => {
                createCourse(e);
              }}
              borderRadius="3px"
              width="75px"
              padding="10px 5px"
              backgroundColor="#286cff"
              className="mx-auto"
            >
              {loading || isLoading ? <Loader /> : "Update"}
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default CreateLesson;
