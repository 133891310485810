import "./popular.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button";
import background from "../../../img/landing.png";
import quality_content from "../../../img/quality_content.svg";
import forum from "../../../img/forum.svg";
import access_anywhere from "../../../img/access_anywhere.svg";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [
        "Algebra",
        "Geometry",
        "Logic",
        "Number Theory",
        "Calculus",
        "Algebra",
        "Geometry",
        "Logic",
        "Number Theory",
        "Calculus",
      ],
    };
  }

  render() {
    const { topics } = this.state;
    return (
      <div className="popular my-12">
        <main className="boxed__container">
          <p className="topics__header py-12">Popular Topics</p>
          <main className="card__wrapper pb-16">
            {topics.map((topic, index) => (
              <div key={index} className=" card rounded-lg  mx-2">
                <p className="card__title pl-8 pt-4">{topic}</p>
              </div>
            ))}
          </main>
        </main>
      </div>
    );
  }
}
