import React, { Component, useState } from "react";
import "./sidemenu.scss";
import { useHistory } from "react-router-dom";
import play from "../../../img/blue-play.svg";


const CourseSideMenuItem = (props) => {
  const [showAssesment, setShowAssement] = useState(false);
  const handleClick = (lesson, type) => {
    props.selectCourseItem(lesson, type);
  };

  const handleSelect = (e, lesson, type) => {
    e.preventDefault();
    const value = e.target.checked;
    if (value) {
      props.markCompleted(e, lesson, type);
    }
  };

  return (
    <div className="course__sidemenu">
      <main className="w-full p-4">
        <main className="my-2 ml-3">
          <main className="flex w-full justify-between">
            <p className="title cursor-pointer">{props.lesson.title}</p>
            <span
              onClick={() => {
                setShowAssement(!showAssesment);
              }}
               dangerouslySetInnerHTML={{__html: showAssesment
                ? 
                    "<i className='fas fa-angle-up cursor-pointer'></i>"
                   :
                  
                    "<i className='fas fa-angle-down cursor-pointer'></i>"
                  }}
              >
            </span>
          </main>
          {showAssesment && (
            <main
              onClick={() => {
                handleClick(props.lesson, "lesson");
              }}
              className="flex mt-2 ml-3"
            >
              <p className="assessment my-2 cursor-pointer flex">
                <input
                  onChange={(e) => {
                    handleSelect(e, props.lesson, "lesson");
                  }}
                  type="checkbox"
                  checked={
                    props.lesson &&
                    props.lesson.status &&
                    props.lesson.status.completed
                  }
                  disabled={
                    props.lesson &&
                    props.lesson.status &&
                    props.lesson.status.completed
                  }
                  className="mr-2"
                />
                <img className="mr-2" src={play} /> Watch Lesson
              </p>
            </main>
          )}
          {showAssesment &&
            props.lesson.assessments &&
            props.lesson.assessments.length > 0 &&
            props.lesson.assessments.map(
              (assessment, i) =>
                (
                  <main className="my-2 ml-3" key={i}>
                    <p className="assessment my-2 cursor-pointer flex">
                      <span className="flex">
                        <input
                          onChange={(e) => {
                            handleSelect(e, assessment, "assessment");
                          }}
                          type="checkbox"
                          checked={
                            assessment &&
                            assessment.status &&
                            assessment.status.completed
                          }
                          disabled={true}
                          className="mr-2"
                        />
                        <span
                          onClick={() => {
                            handleClick(assessment, "assessment");
                          }}
                        >
                          <i class="fas fa-subscript mr-2"></i>{" "}
                          {assessment.title}
                        </span>
                      </span>
                    </p>
                  </main>
                ) || ""
            )}
          {showAssesment && (
            <main
              onClick={() => {
                handleClick(props.lesson, "document");
              }}
              className="flex mt-2 ml-3"
            >
              <p className="assessment my-2 cursor-pointer flex">
                <input
                  onChange={(e) => {
                    handleSelect(e, props.lesson, "document");
                  }}
                  type="checkbox"
                  checked={true}
                  disabled={true}
                  className="mr-2"
                />
                <i class="fas fa-file-pdf mr-2"></i> Read PDF
              </p>
            </main>
          )}
        </main>
      </main>
    </div>
  );
};
export default CourseSideMenuItem;
