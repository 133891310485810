import React, { useState} from "react";

function QuestionPage({quizQuestion}) {

    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [FreeTextInputValue, setFreeTextInputValue] = useState("");
    const [questionCorrect, setQuestionCorrect] = useState();

    const handleButtonClick = (answer) => {
        setSelectedAnswer(answer.id);
        if (answer.id === quizQuestion.correctAnswer) {
            setQuestionCorrect(true);
           } else {
            setQuestionCorrect(false);
           }
    };

    const handleFreeTextSubmit = (inputValue) => {
      const lowerCaseTexts = quizQuestion.answers.map(answer => answer.text.toLowerCase().trim());

      if ( lowerCaseTexts.includes(inputValue.toLowerCase().trim()) ) {
           setQuestionCorrect(true);

      } else {
        setQuestionCorrect(false);
      }

    };

    const handleDropDownSubmit = () => {
       if (selectedAnswer === quizQuestion.correctAnswer) {
        setQuestionCorrect(true);

       } else {
        setQuestionCorrect(false);
       }

    };

    const ButtonColour = (selectedAnswer, answer) => {

        let Formatting = 'whitespace-nowrap h-12 px-2 my-2 text-lg text-black border border-2 border-black hover:bg-yellow-500 rounded-lg focus:outline-none';

        let AdditionalFormatting = ''

        if (selectedAnswer === answer.id && answer.id === quizQuestion.correctAnswer) {
            AdditionalFormatting = ' bg-green-500'
        } 
        else if (selectedAnswer === answer.id && answer.id != quizQuestion.correctAnswer) {
            AdditionalFormatting = ' bg-red-500'
        }
        else {
            AdditionalFormatting = ' bg-white'
        };

        Formatting += AdditionalFormatting;

        return Formatting;

    };

    return (
        <div>
          <h1 className="text-center text-3xl font-bold my-4" style={{ whiteSpace: 'pre-line'}}>{quizQuestion.question}</h1>
          <div className="flex justify-center">
            {quizQuestion.image && quizQuestion.image.trim() !== '' && (
              <img src={quizQuestion.image} alt="Question Image" className="h-auto object-contain" />
            )}
          </div>
      
          {quizQuestion.questionType === 'Multiple-Choice' ? (
            <div className="py-4 flex flex-wrap justify-between">
              {quizQuestion.answers.map((answer) => (
                <button
                  key={answer.id}
                  onClick={() => handleButtonClick(answer)}
                  className={ButtonColour(selectedAnswer, answer)}
                  style={{ width: '48%' }}
                >
                  {answer.text}
                </button>
              ))}
            </div>
          ) : quizQuestion.questionType === 'Drop-Down-List' ? (
            <main className="my-2">
              <p className="form__label">Select Answer</p>
              <select
                value={selectedAnswer}
                onChange={(e) => { setSelectedAnswer(e.target.value) } }
                className="form__border w-full my-2"
              >
                <option value="" disabled>
                  Select Answer
                </option>
                {quizQuestion.answers.map((answer) => (
                  <option key={answer.id} value={answer.id}>
                    {answer.text}
                  </option>
                ))}
              </select>
              <button
                onClick={() => handleDropDownSubmit()}
                className="h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none"
              >
                Submit
              </button>
            </main>
          ) : quizQuestion.questionType === 'Free-Text' ? (
            <main className="my-2">
              <input
                type="text"
                value={FreeTextInputValue}
                onChange={(e) => { setFreeTextInputValue(e.target.value) }}
                className="w-full h-12 p-3 text-lg border-2 border-gray-600 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <button
                onClick={() => handleFreeTextSubmit(FreeTextInputValue)}
                className="h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none"
              >
                Submit
              </button>
            </main>
          ) : null}
            {questionCorrect !== undefined && (
                <div className={`mt-4 p-4 ${questionCorrect ? 'bg-green-100' : 'bg-red-100'} border ${questionCorrect ? 'border-green-500' : 'border-red-500'} rounded-lg`}>
                    <p className="text-lg font-semibold">
                        {questionCorrect ? 'Correct!' : 'Incorrect'}
                    </p>
                    {questionCorrect && quizQuestion.correctFeedback && quizQuestion.correctFeedback.trim() !== '' && (
                        <p className="text-green-700 mt-2" style={{ whiteSpace: 'pre-line'}}>
                            {quizQuestion.correctFeedback}
                        </p>
                    )}
                    {!questionCorrect && quizQuestion.incorrectFeedback && quizQuestion.incorrectFeedback.trim() !== '' && (
                        <p className="text-red-700 mt-2" style={{ whiteSpace: 'pre-line'}}>
                            {quizQuestion.incorrectFeedback}
                        </p>
                    )}
                </div>
            )}
        </div>
      );
};


export default QuestionPage;