import "./sidebar.scss";
import React, { Component, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";

import { StyledButton } from "../Button/button.style";
import { AuthContext } from "../../../contexts/AuthContext";
import image from "../../../img/image.png";

const Sidebar = (props) => {
  const token = localStorage.getItem("maths-user");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const { activeMenu } = props;
  const [routes, setRoutes] = useState([
    {
      name: "View Profile",
      key: "profile",
      route: "/me",
    },
    {
      name: "Profile Photo",
      key: "photo",
      route: "/photo",
    },
    {
      name: "Account",
      key: "account",
      route: "/account",
    },
    // {
    //   name: "Privacy",
    //   key: "privacy",
    //   route: "/privacy",
    // },
    {
      name: "Subscriptions & Billing",
      key: "subscription",
      route: "/subscription",
    },
    // {
    //   name: "Notification",
    //   key: "notification",
    //   route: "/notification",
    // },
    // {
    //   name: "Close Account",
    //   key: "close",
    //   route: "/account",
    // },
  ]);

  return (
    <div className="side__bar text-left">
      <main className="w-full">
        <main className="pl-4 my-8">
          {user && user.profile_image ? (
            <img
              src={user.profile_image}
              alt="User Profile Picture"
              className="rounded-full mx-auto h-24 w-24 "
            />
          ) : (
            <p className="text-center">
              <i className="fas fa-5x text-black fa-user-circle text-center mx-auto"></i>
            </p>
          )}
        </main>
        {routes.map((route, index) => (
          <main
            key={index}
            className={`cursor-pointer w-full py-3 pl-12 nav__link ${
              activeMenu == route.key ? "active__route" : ""
            }`}
          >
            <Link to={route.route}>{route.name}</Link>
          </main>
        ))}
      </main>
    </div>
  );
};

export default withRouter(Sidebar);
