import React, { Component, useState } from "react";
import "./quiz.scss";
import { StyledButton } from "../../Shared/Button/button.style";
import Loader from "../../../components/Shared/Loader";
import QuizItem from "./QuizItem";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { Chart } from "../../../components/Chart";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { post } from "../../../utils/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    padding: "0px",
  },
};

const Quiz = (props) => {
  const route = props.route || "courses";
  const assessment = props.assessment;
  const [index, setIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [completedAssesment, setCompletedAssesment] = useState(false);
  const [notStartedQuiz, setNotStartedQuiz] = useState(true);
  const [loading, setLoading] = useState(false);
  const [preventReload, setPreventReload] = useState(false);
  const [sessionID, setSessionID] = useState("");
  const [data, setData] = useState({
    labels: ["Correct", "Incorrect", "Not Attempted"],
    datasets: [
      {
        label: "Popularity of colours",
        data: [4, 0, 0],
        // you can set indiviual colors for each bar
        backgroundColor: ["green", "red", "yellow"],
        borderWidth: 1,
        redraw: completedAssesment,
      },
    ],
  });

  if (
    assessment &&
    assessment.status &&
    assessment.status.completed &&
    !preventReload
  ) {
    setCompletedAssesment(true);
    setNotStartedQuiz(false);
    setPreventReload(true);
    setIncorrect(assessment.status.totalIncorrect);
    setScore(assessment.status.score);
  }

  const nextQuestion = (e) => {
    e.preventDefault();
    if (index + 1 < assessment.assessment_questions.length) {
      setIndex(index + 1);
    } else {
      setCompletedAssesment(true);
      formatResults();
    }
  };

  const formatResults = () => {
    setData({
      labels: ["Correct", "Incorrect", "Not Attempted"],
      datasets: [
        {
          label: "Popularity of colours",
          data: [
            score,
            incorrect,
            assessment.assessment_questions.length - (score + incorrect),
          ],
          // you can set indiviual colors for each bar
          backgroundColor: ["green", "red", "yellow"],
          borderWidth: 1,
          redraw: completedAssesment,
        },
      ],
    });

    handleSubmitAssesment();
  };

  const { mutate, isLoading } = useMutation(
    (payload) => beginAssesment(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
      },
      onSuccess: (data, variables, context) => {
        setLoading(false);
        setNotStartedQuiz(false);
        setSessionID(data.data.sessionId);
      },
    }
  );

  const beginAssesment = async (assessmentId) => {
    try {
      return await post(
        `/${route}/assessments/${assessmentId}/start`,
        {},
        true
      );
    } catch (e) {
      setLoading(false);
      toast.error("An error occurred");
      return e;
    }
  };

  const handleBeginAssesment = (e) => {
    setLoading(true);
    mutate(assessment._id);
  };

  const { mutate: submitMutation, isLoading: submitLoading } = useMutation(
    (payload) => submitAssesment(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
      },
      onSuccess: (data, variables, context) => {
        setLoading(false);
        setNotStartedQuiz(false);
        setSessionID(data.data.sessionId);
      },
    }
  );

  const submitAssesment = async (sessionId) => {
    try {
      return await post(
        `/${route}/assessments/${sessionId}/submit`,
        {
          score,
          totalCorrect: score,
          totalIncorrect: incorrect,
          totalUnattempted:
            assessment.assessment_questions.length - (score + incorrect),
          answers: [],
        },
        true
      );
    } catch (e) {
      setLoading(false);
      toast.error("An error occurred");
      return e;
    }
  };

  const handleSubmitAssesment = () => {
    setLoading(true);
    submitMutation(sessionID);
  };

  return (
    <main className="quiz__wrapper p-6 w-full rounded shadow w-full">
      <main key={index} className="w-full p-4">
        {notStartedQuiz ? (
          <section>
            <section className="w-full flex flex-wrap justify-center">
              <main>
                <p>You have not started this assesment</p>

                <p>
                  {" "}
                  This assesment contains{" "}
                  {(assessment &&
                    assessment.assessment_questions &&
                    assessment.assessment_questions.length) ||
                    0}{" "}
                  questions.
                  <br /> Click on the button below to get started
                </p>
                <main className=" mt-4 px-2">
                  <StyledButton
                    onClick={(e) => {
                      handleBeginAssesment(e);
                    }}
                    type="submit"
                    borderRadius="3px"
                    width="200px"
                    font-size="14px"
                    backgroundColor="#286cff"
                    disabled={loading ? true : false}
                  >
                    {loading ? <Loader /> : "Begin Assesment"}
                  </StyledButton>
                </main>
              </main>
            </section>
          </section>
        ) : (
          <section>
            {completedAssesment ? (
              <section>
                <section className="w-full flex flex-wrap">
                  <section className="w-full md:w-1/2">
                    <p className="assesment__description">Summary</p>
                    <span className="assesment__description">
                      Total Number of Questions:{" "}
                      {assessment &&
                        assessment.assessment_questions &&
                        assessment.assessment_questions.length}
                    </span>
                    <span className="assesment__description">
                      Number of unanswered questions:
                      {assessment &&
                        assessment.assessment_questions &&
                        assessment.assessment_questions.length &&
                        assessment.assessment_questions.length -
                          (score + incorrect)}
                    </span>
                    <span className="assesment__description">
                      Number of correct answers:
                      {assessment &&
                        assessment.assessment_questions &&
                        assessment.assessment_questions.length &&
                        score}
                    </span>
                    <span className="assesment__description">
                      Number of failed answers:
                      {assessment &&
                        assessment.assessment_questions &&
                        assessment.assessment_questions.length &&
                        incorrect}
                    </span>
                  </section>
                  <section className="w-full md:w-1/2">
                    <Chart chartData={data} />
                  </section>
                </section>
                <main className="flex mt-6 w-full justify-center">
                  <StyledButton
                    onClick={() => {
                      setCompletedAssesment(false);
                      setIncorrect(0);
                      setScore(0);
                      setIndex(0);
                    }}
                    backgroundColor="#fff"
                    padding="8px"
                    width="200px"
                    color="#286cff"
                    className="m-2"
                    border="1.5px solid #286cff;"
                  >
                    Retake Assesment
                  </StyledButton>
                </main>
              </section>
            ) : (
              <section>
                <main className="flex counter justify-between w-full">
                  <p className=" my-2">Question {index + 1}</p>
                  <p>
                    {index + 1}/
                    {assessment &&
                      assessment.assessment_questions &&
                      assessment.assessment_questions.length}
                  </p>
                </main>
                <QuizItem
                  addScore={() => {
                    setScore(score + 1);
                  }}
                  addIncorrect={() => {
                    setIncorrect(incorrect + 1);
                  }}
                  question={assessment.assessment_questions[index]}
                />
                <main className="flex mt-6 w-full justify-end">
                  <StyledButton
                    onClick={() => {
                      if (index != 0) setIndex(index - 1);
                    }}
                    disabled={index == 0}
                    backgroundColor="#fff"
                    padding="8px"
                    width="100px"
                    color="#286cff"
                    className="m-2"
                    border="1.5px solid #286cff;"
                  >
                    Previous
                  </StyledButton>
                  <StyledButton
                    onClick={(e) => {
                      nextQuestion(e);
                    }}
                    backgroundColor="#286cff"
                    padding="8px"
                    width="75px"
                    color="#fff"
                    className="m-2"
                  >
                    Next
                  </StyledButton>
                </main>
              </section>
            )}
          </section>
        )}
      </main>
    </main>
  );
};
export default withRouter(Quiz);
