import "./BeforeFooter.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button";
import footer__icon from "../../../img/footer__icon.png";
import { StyledButton } from "../../Shared/Button/button.style";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

const BeforeFooter = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const token = localStorage.getItem("maths-user");
  return (
    <main className="before__footer">
      <div className="wrapper py-4 ">
        <main className="boxed__container flex flex-wrap text-white items-center">
          <main className="w-full md:w-1/2">
            <p className="footer__title text-white">
              Discuss with other parents and tutors
            </p>
            <p className="footer__right__content my-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore
            </p>
            {token ? (
              <StyledButton
                onClick={() => {
                  handleClick("/dashboard");
                }}
                backgroundColor="#fff"
                color="#286cff"
                width="250px"
                padding="15px 30px"
              >
                Go to Dashboard
              </StyledButton>
            ) : (
              <StyledButton
                onClick={() => {
                  handleClick("/login");
                }}
                backgroundColor="#fff"
                color="#286cff"
                width="250px"
                padding="15px 30px"
              >
                Create an Account
              </StyledButton>
            )}
          </main>
          <main className="w-full md:w-1/2 flex justify-center">
            <img src={footer__icon} />
          </main>
        </main>
      </div>
    </main>
  );
};

export default withRouter(BeforeFooter);
