import React, { useState} from "react";
import { del } from "../../../utils/api";
import { confirmAlert } from "react-confirm-alert";
import QuestionModal from "../QuestionModal";
import QuestionPage from "../QuestionPage";
import CreateQuizQuestion from "../../../views/Quiz/CreateQuizQuestion";
import { toast } from "react-toastify";
import "./../ListQuizQuestions/ListQuizQuestions.scss";

function  QuestionBankList({ 
    quizQuestions,
    quizIdsBeingUsed,
    refetchQuizQuestions,
    refetchQuizzes,
    match , 
    CreateModalOpen, 
    setCreateModalOpen, 
    }) {

    const [PreviewModalOpen, setPreviewModalOpen] = useState(false);
    const [EditModalOpen, setEditModalOpen] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [questionBankOpen, setQuestionBankOpen] = useState(false);
   
    const handleQuestionBankClick = () => {
        setQuestionBankOpen(prevState => !prevState);
    
    };

      const handleDelete = (questionId) => {
        if (quizIdsBeingUsed.includes(questionId)) {
          confirmAlert({
            title: "Error",
            message: "This question is being used in a quiz. Please remove the question from all quizzes before deleting it from the question bank.",
            buttons: [
              {
                label: "OK",
                onClick: () => {},
              },
            ],
          });
            return;
        }
    
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this quiz question?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            setLoading(true);
                            const response = await del(`/quiz/quiz-questions/delete/${questionId}`, true);
                            if (response.status === 200) {
                                refetchQuizQuestions();
                                toast.success('Quiz question deleted successfully');
                                
                            } else {
                                console.log("this is the response", response);
                                toast.error('Error occurred during deletion');
                            }
                        } catch (e) {
                            toast.error("An error occurred: " + e.message);
                        } finally {
                            setLoading(false);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };


    return (
        <div className="w-full flex flex-col items-stretch">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="section-container w-full">
                        <div>
                    <span class="font-bold text-2xl"> Question Bank</span>
                    <button onClick={() => { handleQuestionBankClick();  }} className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out" style={{ marginLeft: '30px' }}>                      
                        {questionBankOpen ? "Close Question Bank" : "Open Question Bank"}                         
                    </button>
                    </div>
                    {Array.isArray(quizQuestions) ? (
                        quizQuestions.length > 0 ? (
                            questionBankOpen ? (
                                quizQuestions.map((question) => (
                                    <div key={question._id} className="flex justify-between items-center mb-4 w-full space-x-4">
                                        <div className="flex items-center space-x-4 flex-1 border-2 border-gray-300 p-2 rounded">
                                            <span className="text-lg text-black" style={{ whiteSpace: 'pre-line'}}>{question.question}</span>
                                            {question.image && question.image.trim() !== '' && (
                                                <img src={question.image} alt="Question Image" className="h-auto object-contain" style={{ width: "50%" }} />
                                            )}
                                        </div>
                                        <div className="flex space-x-2">
                                            <button onClick={() => { setCurrentQuestion(question); setEditModalOpen(true); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-yellow-500 hover:bg-yellow-700 rounded-lg focus:outline-none">Edit Question</button>
                                            <button onClick={() => { setCurrentQuestion(question); setPreviewModalOpen(true); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-blue-500 hover:bg-blue-700 rounded-lg focus:outline-none">Preview Question</button>
                                            <button onClick={() => { setCurrentQuestion(question); handleDelete(question._id); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none">
                                            Delete Question
                                            </button>                                                                                                                  
                                        </div>
                                    </div>
                                ))
                            ) : null
                        ) : (
                            <div>No quiz questions found.</div>
                        )
                    ) : (
                        <div>No quiz questions found.</div>
                    )}

                    <QuestionModal isOpen={EditModalOpen} onClose={() => setEditModalOpen(false)}>
                        <CreateQuizQuestion inputQuizQuestion={currentQuestion} match={match} isEditModalOpen = {setEditModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuestionModal>
                    <QuestionModal isOpen={PreviewModalOpen} onClose={() => setPreviewModalOpen(false)}>
                        <QuestionPage quizQuestion={currentQuestion} />
                    </QuestionModal>
                    <QuestionModal isOpen={CreateModalOpen} onClose={() => setCreateModalOpen(false)}>
                        <CreateQuizQuestion match={match} isCreateModalOpen = {setCreateModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuestionModal>
                </div>
            )}
        </div>
    );
}

export default QuestionBankList;