import React, { Component, useState, useContext } from "react";
import "./preview.scss";
import sam from "../../../img/sam.png";
import { AuthContext } from "../../../contexts/AuthContext";

const TrendCard = (props) => {
  const { chat } = props;
  const user = JSON.parse(localStorage.getItem("maths-app-user"));

  const isReceiver = chat?.reciever?.user_id == user?._id || false;
  const key = isReceiver ? "receiver" : "initiator";
  return (
    <main className="preview__card cursor-pointer w-full">
      <main className="p-4 w-full">
        <main className="flex items-center align-center w-full">
          {chat && chat[key] && chat[key].profile_picture ? (
            <img
              src={chat[key].profile_picture}
              alt="User Profile Picture"
              className="rounded-full  h-12 w-12 "
            />
          ) : (
            <p className="text-center">
              <i className="fas fa-2x text-black fa-user-circle text-center mx-auto"></i>
            </p>
          )}
          {/* <img src={sam} className="h-12" /> */}
          <p className="posted ml-2 ">
            <span className="block name">{chat[key]?.username}</span>
            {chat.last_message ? (
              <span className="desc cursor-pointer">
                {chat.last_message.message}
              </span>
            ) : (
              <span className="desc cursor-pointer invisible">
                nteger sit amet auctor ligula,
              </span>
            )}
          </p>
          {/* <span className="ml-2 font-bold number h-8 w-8 text-center bg-red-300 rounded-full pt-1 text-red-900 justify-self-end">
            2
          </span> */}
        </main>
      </main>
      {/* <hr className="my-3" /> */}
    </main>
  );
};
export default TrendCard;
