import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import "./thread.scss";
import sam from "../../../img/sam.png";
import { useHistory } from "react-router-dom";


const ThreadCard = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const { thread } = props;
  return (
    <main
      onClick={() => {
        handleClick(`/post/${thread.id}`);
      }}
      className="thread__card cursor-pointer  w-full"
    >
      <main className="p-4 w-full shadow rounded-xl">
        <p className="question__title mt-2" dangerouslySetInnerHTML={{__html: thread.title}}></p>
        <p className="question__body my-2" dangerouslySetInnerHTML={{__html: thread.snippet}}></p>
        <hr className="my-3" />
        <main className="flex  w-full">
          <img src={sam} className="h-8" />
          <p className="posted ml-3 mt-1">
            <span className="">posted by: </span>
            <span className="poster cursor-pointer">
              {thread.user.fullname}
            </span>
          </p>
        </main>
      </main>
    </main>
  );
};
export default withRouter(ThreadCard);
