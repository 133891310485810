import React, { Component, useState, useContext } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import Sidebar from "../../components/Shared/Sidebar";
import image from "../../img/image.png";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put } from "../../utils/api";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";

const Photo = (props) => {
  const token = localStorage.getItem("maths-user");
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);

  const { mutate, isLoading } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const initialUpload = async ({ name, type }) => {
    try {
      setLoading(true);
      return post(
        `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
        {},
        true
      );
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const { mutate: mutateProfile, isLoading: isUpdatingProfile } = useMutation(
    (payload) => updateProfile(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setUser(data.data.user);
        toast.success("Profile Update Successful");
        setLoading(false);
      },
    }
  );

  const updateProfile = async (data) => {
    try {
      setLoading(true);
      return put("/users/profile/update", data, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const onFileChange = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      return alert("No file selected.");
    }
    mutate({ name: temp.name, type: temp.type });
  };
  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            mutateProfile({ profile_image: url });
          } else {
            toast.error("An error occurred! Could not upload file.");
          }
        }
      };
      xhr.send(image);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  return (
    <div className="account__page ">
      <Header />
      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="photo" />
          </main>
          <main className="w-full md:w-4/5 border">
            <main className="mx-auto">
              <main className="w-full mx-auto md:w-1/2 my-2 px-2">
                <p className="my-8"></p>
                <p className="text-center page__header">Profile Photo</p>
                <p className="text-center sub__header">
                  Add a picture to your profile
                </p>
                <p className="my-8"></p>
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="User Profile Picture"
                    className="rounded-full mx-auto h-24 w-24 mb-8 mt-4"
                  />
                ) : (
                  <p className="text-center">
                    <i className="fas fa-8x text-black fa-user-circle text-center mx-auto"></i>
                  </p>
                )}
                {/* <img src={image} className="rounded-full mx-auto mb-8 mt-4" /> */}

                <input
                  type="file"
                  placeholder="e.g Ademola"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setPreviewUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                  id="file-input"
                  className={`form__border w-full my-2 `}
                />
                <p className="hint">Note: Size must not be greater than 25mb</p>
                <main className="flex w-full ">
                  <StyledButton
                    onClick={(e) => {
                      onFileChange(e);
                    }}
                    borderRadius="3px"
                    width="75px"
                    padding="10px 5px"
                    backgroundColor="#286cff"
                    className="mx-auto"
                  >
                    {loading || isLoading || isUpdatingProfile ? (
                      <Loader />
                    ) : (
                      "Save"
                    )}
                  </StyledButton>
                </main>
              </main>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Photo;
