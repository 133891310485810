import styled from "styled-components";

export const StyledButton = styled.button`
  width: ${(props) => (props.width ? props.width : "150px")};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "10px"};
  opacity: 1;
  padding: ${(props) => (props.padding ? props.padding : "12px")};
  text-align: center;
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-family: Poppins;
  font-weight: ${(props) => (props.weight ? props.weight : "bold")};
  letter-spacing: 1px;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  border: ${(props) => (props.border ? props.border : "")};
  opacity: 1;
`;
