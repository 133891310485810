import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import { useMutation } from "react-query";
import { post, put} from "../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import FreeTextForm from "../../components/Quiz/addFreeTextAnswers";
import MultipleChoiceForm from "../../components/Quiz/addMultipleChoiceAnswers";
import { useHistory } from 'react-router-dom';

const CreateQuizQuestion = ({inputQuizQuestion, match, isEditModalOpen, isCreateModalOpen, refetchQuizQuestions, refetchQuizzes }) => {
  
    const [EditMode, setEditMode] = useState( inputQuizQuestion ? true : false);

    //the ImageChanged state is used to determine if we need to reupload an aws image if we are in EditMode
    const [ImageChanged, setImagedChanged] = useState(false);

    const [inputs, setInputs] = useState( inputQuizQuestion ? inputQuizQuestion.answers : []);
    const [selectedAnswer, setSelectedAnswer] = useState(inputQuizQuestion ? inputQuizQuestion.correctAnswer : null);
    const [loading, setLoading] = useState(false);

    const [QuizQuestion, setQuizQuestion] = useState({

      questionType : inputQuizQuestion ? inputQuizQuestion.questionType : '',
      question: inputQuizQuestion ? inputQuizQuestion.question : '',
      answers: inputQuizQuestion ? inputQuizQuestion.answers : [],
      correctAnswer: inputQuizQuestion ? inputQuizQuestion.correctAnswer : '',
      image: inputQuizQuestion ? inputQuizQuestion.image : '',
      courseId : inputQuizQuestion ? inputQuizQuestion.courseId : '',
      lessonId : inputQuizQuestion ? inputQuizQuestion.lessonId : '',
      correctFeedback : inputQuizQuestion ? inputQuizQuestion.correctFeedback : '',
      incorrectFeedback : inputQuizQuestion ? inputQuizQuestion.incorrectFeedback : ''
    });

    const [imagePreviewURL, setImagePreviewURL] = useState(inputQuizQuestion ? inputQuizQuestion.image : null);
    const [image, setImage] = useState({});
    const [error, setError] = useState('');

    const fileInputRef = useRef(null);

    const { mutate, isLoading } = useMutation(
        (payload) => initialUpload(payload),
        {
          onError: (error, variables, context) => {
            toast.error(error.response.data.message);
            setLoading(false);
          },
          onSuccess: (data, variables, context) => {
              awsUpload({
                route: (!QuizQuestion.image || (EditMode && !ImageChanged)) ? "dummy route" : data.data.data.signedRequest,
                url: (!QuizQuestion.image || (EditMode && !ImageChanged)) ? "dummy URL" : data.data.data.url,
              });           
          },
        }
      );

    const initialUpload = async ({ name, type }) => {
        setLoading(true);

      if (!QuizQuestion.image || (EditMode && !ImageChanged)) {
        return QuizQuestion.image;
      }

      try { 
        const response = await post(`/services/file/presign-url/?file_name=${name}&file_type=${type}`, {}, true);   
        
        return (response);
      } catch (e) {
        setLoading(false);
        toast.error("An error occurred");
        return e;
      }
    };

   const awsUpload = async ({ route, url }) => {
    if (!QuizQuestion.image || (EditMode && !ImageChanged)) {
      addQuizQuestionMutate({ ...QuizQuestion });
    }
    else {
        try {
          const xhr = new XMLHttpRequest();
    
          xhr.open("PUT", route);
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                addQuizQuestionMutate({ ...QuizQuestion, image: url});
              } else {
                console.error("Error uploading file:", xhr.responseText);
                toast.error("An error occurred! Could not upload file.");
              }
            }
          };
          
          xhr.send(image);
          
        } catch (e) {
          toast.error("An error occurred");
          return e;
        }
    }  
   };

      const { mutate: addQuizQuestionMutate, isLoading : isAddingQuizQuestion } = useMutation(
        (payload) => {EditMode ? EditQuizEntry(payload) : CreateQuizEntry(payload) },
        {
          onError: (error, variables, context) => {
            toast.error(error.response.data.message);
            setLoading(false);
          },
          onSuccess: (data, variables, context) => {
            if (EditMode )
            {
              isEditModalOpen(false);
            }   
            else 
            {
              isCreateModalOpen(false);
            }   
              
          },
        }
      );


    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        if (file && file.type.match('image.*')) {
            reader.onloadend = () => {
                setImagePreviewURL(reader.result);
                setImage(file);
                setQuizQuestion(prevState => ({...prevState, image:file }));
                setImagedChanged(true);
            }

            reader.readAsDataURL(file);
        }

    };

    

    const handleRemoveImage = () => {
        setImagePreviewURL(null);
        setImage({});
        setQuizQuestion(prevState => ({...prevState, image: '' }));
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const updateParentState = (newInputs, newSelectedAnswer) => {
        setQuizQuestion(prevState => ({
            ...prevState,
            answers: newInputs,
            correctAnswer: newSelectedAnswer
        }));

    };

    const handleSubmit = async() => {

        if (!QuizQuestion.questionType || QuizQuestion.questionType.trim() === '') {
          setError('You must set a question Type');
          return;
        }
  
        if (!QuizQuestion.question.trim()) {
            setError('Quiz question cannot be blank.');
            return;
        }

        if (QuizQuestion.questionType === "Free-Text" && QuizQuestion.answers.length === 0 ) {
          setError('There must be at least one Free Text answer option.');
          return;
        }
      
        if (QuizQuestion.questionType != "Free-Text" && QuizQuestion.answers.length <= 1) {
            setError('There must be at least 2 multiple choice options.');
            return;
        }

        if (QuizQuestion.answers.some(input => input.text.trim() === '')) {
            setError('All answer options must be filled.');
            return;
        }
        if (QuizQuestion.questionType !== 'Free-Text' && 
            (!QuizQuestion.correctAnswer || QuizQuestion.correctAnswer === "" || QuizQuestion.correctAnswer === "N/A")) {
            setError('A correct answer must be selected.');
            return;
        }

        setError('');
        setLoading(true);

        try {
           await mutate({name : QuizQuestion.image.name, type : QuizQuestion.image.type});           
        } catch (e) {
            toast.error("An error occurred during the upload process");
            console.error("Upload process error:", e);
            
        } finally {
            setLoading(false);
        }
                       
    };


    const EditQuizEntry = async (data) => {

      const dataToSend = {
        ...data,
        _id:inputQuizQuestion._id
      };

      try {      
        setLoading(true);
        // Assuming the endpoint to update a quiz question takes the ID as a parameter
        const response = await put(`/quiz/quiz-questions/update/${dataToSend._id}`, dataToSend, true);
        toast.success("Quiz Question updated successfully");  
        return response;
        
    } catch (e) {
        toast.error("An error occurred: " + (e.response?.data?.message || e.message));
        return e;
    } finally {
      refetchQuizzes();
      refetchQuizQuestions();
        setLoading(false);
    }

    };

    const CreateQuizEntry = async (data) => {
        try {          
          setLoading(true);

          const course = match.params.id;
          const lesson = match.params.lesson;

          data = {...data, courseId : course, lessonId : lesson};

          const url = `/quiz/quiz-questions/create`;

          const response = await post(url, data, true);
          toast.success("Quiz Question created successfully");  

          return response;

        } catch (e) {

            console.error("Error creating quiz question:", e);
            toast.error("An error occurred");
            return e;
        } finally {
          
          refetchQuizQuestions();
          setLoading(false);
        }

    };

    return(
        <div className="course__page">
              <div className="py-6" />
                    <main className="boxed__container">
                        <main className="w-full md:w-3/5 mx-auto">
                            <p>{ EditMode ? "Edit Quiz Question" : "Create New Quiz Question"}</p>
                            <main className="my-2">
                                  <p className="form__label">Question Type</p>
                                  <select
                                    value={QuizQuestion.questionType}
                                    onChange=
                                    {
                                      (e) => setQuizQuestion(prevState => ({...prevState, questionType : e.target.value}))
                                    }
                                    className={`form__border w-full my-2`}
                                  >
                                    <option value="" disabled>Select Question Type</option>
                                    <option value="Multiple-Choice">Multiple Choice</option>
                                    <option value="Drop-Down-List">Drop Down List</option>
                                    <option value="Free-Text">Free Text</option>
                                  </select>
                                </main>
                                <main className="my-2">
                                    <p className="form__label">Quiz Question</p>
                                        <textarea
                                            /*type="text"*/
                                            placeholder="type your question here"
                                            value={QuizQuestion.question}
                                            onChange={(e) =>
                                                setQuizQuestion(prevState => ({...prevState, question : e.target.value}))
                                            }
                                            className={`form__border w-full my-2 `}
                                        />
                                </main>
                                <main className="my-2">
                                    <p className="form__label">Question Image</p>
                                        <input
                                            type="file"
                                            placeholder="select a image for question"
                                            onChange={ handleImageChange}
                                            id="file-input"
                                            className={'form__border w-full my-2'}
                                            ref={fileInputRef}
                                            /> 
                                            <div className = "flex items-center space-x-4 my-2">
                                            {imagePreviewURL && (
                                                <img src={imagePreviewURL} alt="Quiz Question" style={{ width: '500px', height: 'auto' }} />
                                            )}
                                            {imagePreviewURL && (
                                                <button onClick={handleRemoveImage} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                Remove Image
                                                </button>
                                            )}  
                                            </div>                               
                                </main>   
                                <main className="my-2">
                                  { QuizQuestion.questionType === 'Multiple-Choice' ?
                                  <p className="form__label">Multiple Choice Answers</p>
                                  :  ( QuizQuestion.questionType === 'Drop-Down-List' ? <p className="form__label">Dropdown Answers</p> : <p className="form__label">Free Text Answers</p> )
                                  }
                                  {QuizQuestion.questionType === 'Free-Text' ? 
                                  <FreeTextForm
                                  inputs = {inputs}
                                  setInputs = {setInputs}
                                  updateAnswers={updateParentState}
                                  setSelectedAnswer = {setSelectedAnswer}                             
                                  />
                                  
                                  :                                   
                                  <MultipleChoiceForm 
                                  QuestionType = { QuizQuestion.questionType }
                                  inputs = {inputs}
                                  setInputs = {setInputs}
                                  selectedAnswer = {selectedAnswer}
                                  setSelectedAnswer = {setSelectedAnswer}
                                  updateAnswers={updateParentState}                                
                              />                             
                                  }
                                  <div className="my-2">
                                          <p className="form__label">Correct Answer feedback (Optional)</p>
                                          <textarea
                                              placeholder="type corect answer feedback here"
                                              value={QuizQuestion.correctFeedback}
                                              onChange={(e) =>
                                                  setQuizQuestion(prevState => ({...prevState, correctFeedback: e.target.value}))
                                              }
                                              className="form__border w-full my-2"
                                              /*rows={4}*/ // You can adjust the number of rows as needed
                                          />
                                  </div>
                                  <div className="my-2">
                                          <p className="form__label">Incorrect Answer feedback (Optional)</p>
                                          <textarea
                                              placeholder="type incorrect answer feedback here"
                                              value={QuizQuestion.incorrectFeedback}
                                              onChange={(e) =>
                                                  setQuizQuestion(prevState => ({...prevState, incorrectFeedback: e.target.value}))
                                              }
                                              className="form__border w-full my-2"
                                              /*rows={4}*/ // You can adjust the number of rows as needed
                                          />
                                  </div>                                       
                                        <div className="py-2">
                                        {error && <div className="text-red-500">{error}</div>}
                                        <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            {loading || isLoading || isAddingQuizQuestion ? <Loader/> : (EditMode ?  "Edit Quiz Question" : "Create Quiz Question") } 
                                        </button>
                                        </div>
                                </main>                      
                        </main>
                                       
                    </main>          
        </div>
    )
};


export default CreateQuizQuestion;