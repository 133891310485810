import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import BackButton from "../../components/Forum/Back";
import { StyledCard } from "./card.style";
import { StyledButton } from "../../components/Shared/Button/button.style";
import "./index.scss";
import lesson1 from "../../img/lesson1.png";
import lesson2 from "../../img/lesson2.png";
import lesson3 from "../../img/lesson3.png";
import lesson4 from "../../img/lesson4.png";
import play from "../../img/play.png";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import { api, get, post } from "../../utils/api";
import { useMutation, useQueries, useQuery } from "react-query";

const Glossary = (props) => {
  const history = useHistory();

  const token = localStorage.getItem("maths-user");
  const [search, setSearch] = useState("");
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState(props.match.params.tag);

  const {
    isLoading,
    error,
    data,
    refetch: refetchData,
  } = useQuery("fetchSearchGlossary", () =>
    get(`/courses/glossary/search?tag=${tag}`, true).then((res) => {
      setLessons(res.data.lessons);
    })
  );
  return (
    <div className="lessons__page ">
      <Header />
      <div className="py-8" />
      <main className="py-16 w-full boxed__container ">
        <div className="my-12" />
        <BackButton route="/glossary" className="pt-8 " />

        <p className="header__text text-left mt-4 capitalize">{tag}</p>

        <main className=" my-8 flex flex-wrap  top__wrapper ">
          {lessons &&
            lessons.map((lesson, index) => (
              <main key={index} className="w-full md:w-1/4 p-4">
                <StyledCard
                  style={{
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 44.3%, #000000 125.99%), url(${lesson.image})`,
                  }}
                  className=" h-64 flex items-end w-ful text-white px-3 mx-2 rounded-2xl text-black"
                >
                  <main className="flex justify-between w-full">
                    <span className="step">dddd </span>
                    <img src={play} />
                  </main>
                </StyledCard>
                <main className="my-2 ml-3">
                  <p className="title">{lesson.title}</p>
                  <p className="duration">{lesson.duration}</p>
                </main>
              </main>
            ))}
        </main>

        <main className="mx-auto">
          {!lessons || !lessons.length ? <Loader /> : ""}
        </main>
      </main>
    </div>
  );
};

export default Glossary;
