import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import TrendCard from "../../components/Forum/Trend";
import ThreadCard from "../../components/Forum/Thread";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import { api, get, post, handleError } from "../../utils/api";
import "./index.scss";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQueries, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import ReactPaginate from "react-paginate";
const queryString = require("query-string");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    padding: "0px",
  },
};

const Forum = (props) => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [category, setCategory] = useState(props.match.params.categoryId);
  const [view, setView] = useState("trend");
  const [loading, setLoading] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [allThreadsCount, setAllThreadsCount] = useState(0);
  const [threadsCurrentPage, setThreadsCurrentPage] = useState(
    queryString.parse(props.location.search).page || 1
  );
  const [recentCurrentPage, setRecentCurrentPage] = useState(
    queryString.parse(props.location.search).page || 1
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const token = localStorage.getItem("maths-user");
  function openModal() {
    setIsOpen(true);
    setTitle("");
    setBody("");
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onSubmit = (event) => {
    setBodyError(false);
    mutate({ title, body, category });
  };

  const {
    data: usersData,
    isLoading: loadingCategories,
    refetch: refetchCatgories,
  } = useQuery("allForumCategoriesData", () =>
    get(`forums/category/fetch-all`, true).then((res) => {
      setCategories(res.data.data);
    })
  );

  const handlePageClick = (event) => {
    setThreadsCurrentPage(event.selected + 1);
    setAllThreadsCount(event.selected + 1);
    refetchData();
    refetchByResponse();
    window.scrollTo(0, 0);
    history.push({
      pathname: `/forum/category/${props.match.params.categoryId}`,
      search: `?page=${event.selected + 1}`,
    });
  };

  const { mutate, isLoading: createLoader } = useMutation(
    (payload) => createPost(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setLoading(false);
        if (data && data.status < 400) {
          toast.success("Thread created Successful");
          refetchByResponse();
          refetchData();
          setTitle("");
          setBody("");
          setIsOpen(false);
        }
      },
    }
  );

  const createPost = async (data) => {
    try {
      setLoading(true);
      const res = await post("/forums/create-thread", data, true);
      setLoading(false);
      toast.success("Thread created Successful");
      history.push(`/post/${res.data.thread._id}`);
    } catch (e) {
      setLoading(false);
      const message = await handleError(e);
      toast.error(message);
    }
  };

  const {
    isLoading: loadingData,
    error,
    data,
    refetch: refetchData,
  } = useQuery("formumbdData", () =>
    get(
      `/forums/fetch-all-threads?category=${props.match.params.categoryId}&pageSize=10&page=${threadsCurrentPage}`,
      true
    ).then((res) => {
      setAllThreadsCount(res.data.pagination.total);
      return res.data.threads.reverse();
    })
  );

  const {
    isLoading: byResponseLoader,
    error: byResponseError,
    data: byResponseData,
    refetch: refetchByResponse,
  } = useQuery("byResponse", () =>
    get(
      `/forums/fetch-all-threads?category=${props.match.params.categoryId}&type=by_response&pageSize=10&page=${threadsCurrentPage}`,
      true
    ).then((res) => res.data.threads.reverse())
  );

  const handleChange = (value) => {
    setBody(value);
  };

  return (
    <div className="forum_page ">
      {modalIsOpen ? "" : <Header />}

      <main className="py-16 w-full ">
        <main className="boxed__container">
          <p className="forum__header pt-12 text-center">
            Welcome to the Mathematica Community
          </p>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            // className="my__modal"
          >
            <main className="h-32 flex justify-end p-8 items-end top__part">
              <main
                onClick={() => closeModal()}
                className="bg-white rounded-full text-2xl shadow h-12 w-12 pt-1 text-center cursor-pointer"
              >
                x
              </main>
            </main>
            <p className="modal__header pt-8 text-center">Post a Thread</p>
            <p className="modal__subtitle text-center py-2 mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit);
              }}
            >
              <main className="flex flex-wrap w-full justify-center mt-8">
                <div className="w-full">
                  <main className="w-full md:w-3/5 mx-auto my-4 ">
                    <p className="modal__label my-1">Title</p>
                    <input
                      type="text"
                      placeholder="e.g Solving Fractions"
                      {...register("title", { required: true, minLength: 2 })}
                      className={`modal__form__border w-full my-2  w-full my-2 ${
                        errors.title ? "red__form__border" : ""
                      }`}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <p className="error__message">
                      {errors &&
                        errors.title &&
                        errors.title.type === "required" &&
                        "Title is required"}
                      {errors &&
                        errors.title &&
                        errors.title.type === "minLength" &&
                        "Title must be a minimum of 10 characters"}
                    </p>
                  </main>
                </div>
                <div className="w-full flex justify-center my-4">
                  <main className="w-full mx-auto md:w-3/5">
                    <select
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                      className={`modal__form__border w-full my-2  w-full my-2 ${
                        errors.title ? "red__form__border" : ""
                      }`}
                    >
                      <option>Select a category</option>
                      {categories.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </main>
                </div>
                <div className="w-full flex justify-center my-4">
                  <main className="w-full mx-auto md:w-3/5">
                    <p className="modal__label my-1">More Information</p>
                    <ReactQuill
                      className=" w-full mb-8 mx-auto h-32"
                      value={body}
                      onChange={handleChange}
                    />

                    <p className="error__message">
                      {bodyError &&
                        "Details is required must be a minimum of 100 characters"}
                    </p>
                  </main>
                </div>
                <main className="w-full flex  mt-8 justify-center">
                  <StyledButton
                    type="submit"
                    weight="normal"
                    size="12px"
                    padding="10px 10px"
                    backgroundColor="#286cff"
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                  >
                    {loading ? <Loader /> : " Post Thread"}
                  </StyledButton>
                </main>
              </main>
            </form>
          </Modal>
          <p className="forum__subheader text-center py-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <main className="flex mx-auto justify-center">
            <div className="relative w-1/2  mx-auto text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex justify-center items-center pl-3">
                <button
                  type="submit"
                  className="p-1 pl-2 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="q"
                className="ml-3 form__border w-full  my-2 mx-auto text-sm text-white rounded-md pl-10 focus:outline-none bg-white text-gray-900"
                placeholder="        Search the community or ask a question"
                autocomplete="off"
              />
            </div>
            {/* <input
              type="text"
              className="form__border w-full md:w-1/3 my-2 mx-auto"
              placeholder="Search the community or ask a question"
            /> */}
          </main>
        </main>
        <div className="h-12" />

        <main className="flex flex-wrap boxed__container my-8">
          <main className="w-full ">
            {title}
            <main className="my-4 px-4 ml-4 ">
              <main className="flex justify-between flex-wrap">
                <p className="trending">
                  <span
                    onClick={() => {
                      setView("trend");
                      setThreadsCurrentPage(1);
                      setRecentCurrentPage(1);
                    }}
                    className={`pb-3 pr-6 cursor-pointer ${
                      view == "trend" ? "active__tab" : "inactive__tab"
                    }`}
                  >
                    Recent Trends
                  </span>
                  <span
                    onClick={() => {
                      setView("response");
                      setThreadsCurrentPage(1);
                      setRecentCurrentPage(1);
                    }}
                    className={`pb-3 mr-1 pl-6 cursor-pointer ${
                      view == "response" ? "active__tab" : "inactive__tab"
                    }`}
                  >
                    By Response Number
                  </span>
                </p>

                <main className="flex">
                  <StyledButton
                    onClick={() => openModal()}
                    weight="normal"
                    size="12px"
                    padding="10px 5px"
                    backgroundColor="#286cff"
                  >
                    Create Post
                  </StyledButton>
                </main>
              </main>
            </main>
            {view === "trend" ? (
              <main
                className={`mx-4 md:ml-8 md:mr-4  ${token ? "-mt-2" : "mt-6"}`}
              >
                {loadingData ? (
                  <Skeleton className="mt-4" count={5} />
                ) : (
                  data.map((thread) => (
                    <main key={thread.id} className="py-2">
                      <ThreadCard thread={thread} />
                      <hr className="mx-4" />
                    </main>
                  ))
                )}
              </main>
            ) : (
              <main className="mx-4 md:ml-8 md:mr-4 -mt-2">
                {byResponseLoader ? (
                  <Skeleton className="mt-4" count={5} />
                ) : (
                  byResponseData.map((thread) => (
                    <main key={thread.id} className="py-2">
                      <ThreadCard thread={thread} />
                      <hr className="mx-4" />
                    </main>
                  ))
                )}
              </main>
            )}
            <main className="flex flex-column justify-end pagination my-2">
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="inline mx-2 border px-2 pagination__bg"
                previousClassName="inline mx-2 border w-12 px-2 pagination__bg"
                activeClassName="bg-blue-300"
                nextClassName="inline mx-2 border px-2 pagination__bg"
                pageCount={allThreadsCount / 10}
                forcePage={threadsCurrentPage - 1}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                pageClassName="inline mx-2 border px-2 pagination__bg"
                onPageChange={handlePageClick}
                containerClassName="="
              />
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Forum;
