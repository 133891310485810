import React, { useState, useRef } from "react";
import Header from "../../components/Shared/Header";
import { withRouter } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { post, get, del } from "../../utils/api";
import { confirmAlert } from "react-confirm-alert";
import "./index.scss";

const QuizMusic = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [mp3File, setMp3File] = useState(null);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);

  const { data: musicTracks } = useQuery("musicTracks", async () => {
    const response = await get("/music-tracks");
    return response.data;
  });

  const { mutate: generatePresignedUrl, isLoading: generatingUrl } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
      },
    }
  );

  const initialUpload = async ({ name, type }) => {
    setLoading(true);

    if (!mp3File) {
      return mp3File;
    }

    try {
      const response = await post(`/services/file/presign-url/?file_name=${name}&file_type=${type}`, {}, true);
      return response;
    } catch (e) {
      setLoading(false);
      toast.error("An error occurred");
      return e;
    }
  };

  const awsUpload = async ({ route, url }) => {
    if (!mp3File) {
      saveMusicTrackMutate({ title, mp3: url });
    } else {
      try {
        const response = await fetch(route, {
          method: "PUT",
          body: mp3File,
        });

        if (response.ok) {
          saveMusicTrackMutate({ title, mp3: url });
        } else {
          toast.error("An error occurred! Could not upload file.");
        }
      } catch (e) {
        toast.error("An error occurred");
      }
    }
  };

  const { mutate: saveMusicTrackMutate, isLoading: savingTrack } = useMutation(
    (newTrack) => post("/music-tracks", newTrack),
    {
      onError: (error) => {
        toast.error("Error saving music track");
      },
      onSuccess: () => {
        toast.success("Music track uploaded successfully");
        setModalOpen(false);
        setTitle("");
        setMp3File(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        queryClient.invalidateQueries("musicTracks");
        setLoading(false);
      },
    }
  );

  const { mutate: deleteMusicTrack } = useMutation(
    (id) => del(`/music-tracks/${id}`),
    {
      onSuccess: () => {
        toast.success("Music track deleted successfully");
        queryClient.invalidateQueries("musicTracks");
      },
      onError: () => {
        toast.error("Error deleting music track");
      },
    }
  );

  const handleDelete = (track) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this music track?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteMusicTrack(track);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("audio.*")) {
      setMp3File(file);
    }
  };

  const handleSubmit = () => {
    if (!title.trim()) {
      toast.error("Title cannot be blank");
      return;
    }
    if (!mp3File) {
      toast.error("An MP3 file must be selected");
      return;
    }
    generatePresignedUrl({ name: mp3File.name, type: mp3File.type });
  };

  return (
    <div className="QuizMusic__page">
      <Header />
      <div className="boxed__container">
        <main className="py-16 w-full">
          <div className="top__wrapper block md:flex justify-between rounded-lg items-center my-16">
            <div className="py-6">
              <p className="name capitalize">Quiz Music</p>
            </div>
            <div className="p-2 rounded-lg">
              <StyledButton
                onClick={() => setModalOpen(true)}
                backgroundColor="#286cff"
                color="#fff"
                width="250px"
              >
                Upload Music Track
              </StyledButton>
            </div>
          </div>
          <div className="w-full max-w-none my-16 justify-between rounded-lg items-center wider">
            <div className="music-list">
              {musicTracks?.map((track) => (
                <div key={track._id} className="music-item">
                  <span>{track.title}</span>
                  <audio controls src={track.mp3} />
                  <StyledButton
                    onClick={() => handleDelete(track._id)}
                    backgroundColor="#ff0000"
                    color="#fff"
                    width="100px"
                  >
                    Delete
                  </StyledButton>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setModalOpen(false)}>
              &times;
            </span>
            <h2>Upload Music Track</h2>
            <input
              type="text"
              placeholder="Track Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="file"
              accept="audio/*"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <StyledButton onClick={handleSubmit} disabled={generatingUrl || savingTrack || loading}
                          backgroundColor="#286cff"
                          color="#fff"
                          width="250px">
              {generatingUrl || savingTrack || loading ? <Loader /> : "Submit"}
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(QuizMusic);