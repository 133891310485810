import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, del, handleError } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";

const Dashboard = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [challenges, setChallenges] = useState([]);
  const [loading2, setLoading] = useState(false);

  const {
    isLoading: loading,
    error: challengesError,
    data: challengesData,
    refetch: refetchData,
  } = useQuery("fetchchallenges", () =>
    get("/challenges/all", true).then((res) => {
      setChallenges(res.data.courses);
    })
  );
  const handleDelete = (course) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this challenge",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            mutate(course.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const { mutate, isLoading } = useMutation((id) => deleteCourse(id), {
    onError: (error, variables, context) => {
      const message = handleError(error);
      toast.error(message);
      setLoading(false);
    },
    onSuccess: (data, variables, context) => {
      toast.success("Challenge deleted Successfully");
      refetchData();
      setLoading(false);
    },
  });

  const deleteCourse = async (id) => {
    try {
      setLoading(true);
      return del(`challenges/delete-course/${id}`, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleClick = (route) => {
    history.push(route);
  };

  return (
    <div className="dashboard__page ">
      <Header />

      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 boxed__container top__wrapper block md:flex justify-between rounded-lg items-center">
          <main className="py-6">
            <p className="name capitalize">Welcome, {user.username}.</p>
            <p className="others">
              Please find below challenges you have created
            </p>
          </main>
          <main>
            <StyledButton
              onClick={() => {
                handleClick("/create/challenge");
              }}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
            >
              Create a New Challenge
            </StyledButton>
          </main>
        </main>
        <div className="my-12" />

        <main className="shadow rounded-xl mx-4 md:ml-4 md:mr-12">
          {loading ? <Skeleton className="mt-4" count={5} /> : ""}
        </main>

        {/* Show challenges if not loading, not fetching Subscripion and has subscription */}
        <main className="  md:ml-4 md:mr-12">
          {!loading ? (
            <main className="shadow mx-4 rounded-xl my-16 flex flex-wrap boxed__container top__wrapper ">
              {challenges &&
                challenges.map((course, index) => (
                  <main key={index} className="w-full md:w-1/4 p-4">
                    <StyledCard
                      onClick={() => {
                        handleClick(`/challenge/${course.id}`);
                      }}
                      style={{
                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 44.3%, #000000 125.99%), url(${course.image})`,
                        cursor: "pointer",
                      }}
                      className=" h-64 flex items-end w-ful text-white p-6 mx-2 rounded-2xl"
                    >
                      <main className="flex items-center justify-between w-full">
                        <main>
                          <p className="card__title">{course.title}</p>
                          <p className="card__lessons">
                            {(course.lessons && course.lessons.length) || 0}{" "}
                            lessons
                          </p>
                        </main>

                        {/* <p className="card__duration">9</p> */}
                      </main>
                    </StyledCard>
                    <main className="flex justify-end mr-4 card__actions cursor-pointer">
                      <p
                        onClick={() => {
                          history.push(`/challenge/${course.id}`);
                        }}
                        className="m-1  text-blue-500"
                      >
                        View Lessons
                      </p>
                      <p
                        onClick={() => {
                          history.push(`/challenge/edit/${course.id}`);
                        }}
                        className="m-1  text-blue-500"
                      >
                        Edit
                      </p>
                      <p
                        className="m-1 text-red-500 cursor-pointer"
                        onClick={() => {
                          handleDelete(course);
                        }}
                      >
                        Delete
                      </p>
                    </main>
                  </main>
                ))}
            </main>
          ) : (
            ""
          )}
        </main>
      </main>
    </div>
  );
};

export default withRouter(Dashboard);
