import React from "react";
import { StyledButton } from "../../components/Shared/Button/button.style";
import "./index.scss";

const Error = (props) => {
  return (
    <div className="error_page ">
      {/* <Header /> */}
      <div className="py-8" />
      <main className="py-16 w-full ">
        <p className="my-16 md:my-32"></p>
        <main className=" text-center  boxed__container block  rounded-lg">
          <p className="top__text">Oops!!!, sorry,This might be from us</p>
          <p className="code">404</p>
          <p className="bottom__text">
            Please, click on the refresh button to try again or go back home
          </p>
          <main className="w-full my-8 px-2">
            <StyledButton
              type="submit"
              onClick={() => {
                props.history.push("/");
              }}
              borderRadius="3px"
              width="200px"
              backgroundColor="#286cff"
            >
              Go Home
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Error;
