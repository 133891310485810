// import "./button.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import Button from "./button";

export const StyledHeader = styled.p`
  color: ${(props) => props.color} !important;
  font-family: Poppins;
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: bold;
  text-align: ${(props) => (props.align ? props.align : "left")};
  letter-spacing: 0.95px;
  /* color: #009c2a; */
  opacity: 0.87;
`;
