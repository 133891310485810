import React, { Component, useState } from "react";
import "./comment.scss";
import sam from "../../../img/sam.png";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

const CommentCard = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const { comment } = props;
  return (
    <main className="comment__card p-6 w-full rounded shadow w-full">
      <main className="">
        <main className="flex  w-full">
          <img src={sam} className="h-8" />
          <p className="posted ml-3 mt-1">
            <span
              onClick={() => {
                handleClick("/user");
              }}
              className="poster cursor-pointer"
            >
              {comment.fullname}
            </span>
          </p>
        </main>
        <hr className="mt-2" />
        <p
          className="comment mt-3"
          dangerouslySetInnerHTML={{
            __html: comment.comment,
          }}
        ></p>
      </main>
    </main>
  );
};
export default withRouter(CommentCard);
