import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, del, handleError, patch } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import Loader from "../../components/Shared/Loader";

const CreateCategory = (props) => {
    const history = useHistory();

    const [image, setImage] = useState(null);
    const [category, setCategory] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        get(`/exams/category/${props.match.params.id}/fetch-all`, true).then((res) => {
            setCategory(res.data.data?.category);
        });
    }, [])


    const initialUpload = async ({ name, type }) => {
        try {
            setLoading(true);
            const response = await post(
                `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
                {},
                true
            );

            if (response.data?.data) {
                awsUpload({
                    route: response.data.data.signedRequest,
                    url: response?.data.data.url,
                });
            } else {
                toast.error(response.data.message);
                setLoading(false);
            }

        } catch (e) {
            toast.error("An error occurred");
            return e;
        }
    };

    const handleSubmit = async (e) => {
        // Update the state
        setLoading(true);
        e.preventDefault();
        const files = document.getElementById("file-input").files;
        const temp = files[0];
        if (temp == null) {
            setLoading(false);
            return alert("No file selected.");
        }
        initialUpload({ name: temp.name, type: temp.type });
    };


    const awsUpload = async ({ route, url }) => {
        try {
            const xhr = new XMLHttpRequest();

            xhr.open("PUT", route);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // console.log({ url })
                        setImage(url)
                        // updateCategory();
                        handleCreateCategory({ ...category, cover_image_url: url, });
                    } else {
                        toast.error("An error occurred! Could not upload file.");
                    }
                }
            };
            xhr.send(image);
        } catch (e) {
            toast.error("An error occurred");
            return e;
        }
    };

    const handleCreateCategory = async (data) => {
        try {
            setLoading(true);
            const res = await post("/exams/category/create", data, true);
            props.onCompleted();
            setCategory({});
            setLoading(false);
            toast.success("Category created Successful");

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (e) {
            setLoading(false);
            const message = await handleError(e);
            toast.error(message);
        }
    };

    return (
        <div className=" ">
            <p className="modal__header pt-8 text-center">
                Create Exam Category
            </p>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <main className="flex flex-wrap w-full justify-center mt-8">
                    <div className="w-full">
                        <main className="w-full md:w-3/5 mx-auto my-4 ">
                            <p className="modal__label my-1">Name</p>
                            <input
                                type="text"
                                placeholder="e.g Solving Fractions"
                                className={`modal__form__border w-full my-2 ${errors.title ? "red__form__border" : ""
                                    }`}
                                value={category?.name}
                                onChange={(e) =>
                                    setCategory({ ...category, name: e.target.value })
                                }
                            />
                        </main>
                    </div>
                    <div className="w-full">
                        <main className="w-full md:w-3/5 mx-auto my-4 ">
                            <p className="modal__label my-1">Description</p>
                            <input
                                type="text"
                                placeholder="e.g Solving Fractions"
                                value={category?.description}
                                className={`modal__form__border w-full my-2  ${errors.title ? "red__form__border" : ""
                                    }`}
                                onChange={(e) =>
                                    setCategory({
                                        ...category,
                                        description: e.target.value,
                                    })
                                }
                            />
                        </main>

                        <main className="w-full md:w-3/5 mx-auto my-4 ">

                            <p className="form__label">Cover Image</p>
                            <input
                                type="file"
                                // placeholder="Cover image"
                                onChange={(e) => {
                                    setImage(e.target.files[0]);
                                }}
                                accept="image/*"
                                id="file-input"
                                className={`form__border w-full my-2 `}
                            />
                        </main>
                    </div>

                    <main className="w-full flex  mt-8 justify-center">
                        <StyledButton
                            type="submit"
                            weight="normal"
                            size="12px"
                            padding="10px 10px"
                            backgroundColor="#286cff"
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            {loading ? <Loader /> : " Create Category"}
                        </StyledButton>
                    </main>
                </main>
            </form>

        </div>
    );
};

export default withRouter(CreateCategory);
