import React, { useState, useEffect } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { get } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

const QuizResults = ({ match }) => {
  const [quizSubmissions, setQuizSubmissions] = useState([]);
  const [childrenLoading, setChildrenLoading] = useState(true);
  const [quizSubmissionsLoading, setQuizSubmissionsLoading] = useState(true);
  const { userId } = match.params; // Extract userId from URL params

  const [children, setChildren] = useState([]);
  const [selectedChildId, setSelectedChildId] = useState("");

  const [selectedAttempts, setSelectedAttempts] = useState({});

  useEffect(() => {
    const fetchChildren = async () => {
      setChildrenLoading(true);
      try {
        const { data } = await get(`/users/profile/children/${userId}`, true);
        setChildren(data.children);
        if (data.children.length > 0 && !selectedChildId) {
          setSelectedChildId(data.children[0]._id); // Default to first child if none selected
        }
      } catch (error) {
        toast.error("An error occurred while fetching children");
      } finally {
        setChildrenLoading(false);
      }
    };
  
    if (userId) {
      fetchChildren();
    }
  }, [userId]);

  useEffect(() => {
    const fetchQuizSubmissions = async () => {

      if (!selectedChildId) {
        setQuizSubmissionsLoading(false);
        return;
      }

      try {
        const { data } = await get(`/quiz/quiz-submissions/search-decorated?userId=${userId}&childId=${selectedChildId}&QuizStatus=Complete`);
        setQuizSubmissions(data);

        // Initialize selected attempts for each lessonId
        const initialSelectedAttempts = data.reduce((acc, submission) => {
            const lessonId = submission.lessonId.id;
            if (!acc[lessonId]) {
              acc[lessonId] = submission.AttemptNumber;
            } else if (submission.AttemptNumber > acc[lessonId]) {
              acc[lessonId] = submission.AttemptNumber;
            }
            return acc;
          }, {});
        setSelectedAttempts(initialSelectedAttempts);
        
      } catch (error) {
        toast.error("An error occurred while fetching quiz submissions");
      } finally {
        setQuizSubmissionsLoading(false);
      }
    };

    if (selectedChildId) {
      fetchQuizSubmissions();
    }
  }, [selectedChildId, userId]);


  const getChildName = (childId) => {
    const child = children.find(child => child._id === childId);
    return child ? child.name : '';
  };

  // Group submissions by course title and then by lessonId
  const courseTitlesMap = quizSubmissions.reduce((acc, submission) => {
    const courseTitle = submission.courseId.title;
    const lessonId = submission.lessonId.id;
    if (!acc[courseTitle]) {
      acc[courseTitle] = {};
    }
    if (!acc[courseTitle][lessonId]) {
      acc[courseTitle][lessonId] = [];
    }
    acc[courseTitle][lessonId].push(submission);
    return acc;
  }, {});

  const handleAttemptChange = (lessonId, e) => {
    setSelectedAttempts({
      ...selectedAttempts,
      [lessonId]: parseInt(e.target.value, 10)
    });
  };

  return (
    <div className="course__page">
      <Header />
      <div className="py-24">
        <main className="boxed__container">
          {childrenLoading ? (
            <Skeleton count={5} />
          ) : (
            <div className="flex flex-col boxed_-container">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden sm:rounded-lg shadow-md">
                    <h1 className="text-2xl font-semibold mb-6">Quiz Results</h1>
                    {children.length === 0 ? (
                      <p>No children have been set for this user.</p>
                    ) : (
                      <>
                        <div className="mb-4">
                          <label htmlFor="childSelect" className="block text-sm font-medium text-gray-700">
                            Select Child
                          </label>
                          <select
                            id="childSelect"
                            value={selectedChildId}
                            onChange={(e) => setSelectedChildId(e.target.value)}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            {children.map((child) => (
                              <option key={child._id} value={child._id}>
                                {child.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <h1 className="text-2xl font-semibold mb-6">
                          {quizSubmissions.length > 0
                            ? `${getChildName(selectedChildId)}'s Quiz Results`
                            : 'Quiz Results will appear here when Quizzes have been completed'}
                        </h1>
                        {quizSubmissionsLoading ? (
                          <Skeleton count={5} />
                        ) : (
                          Object.keys(courseTitlesMap).map((courseTitle) => (
                            <div key={courseTitle} className="mb-8">
                              <h2 className="mt-4 text-lg font-medium">{courseTitle}</h2>
                              <table className="min-w-full">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th scope="col" className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider">
                                      Lesson
                                    </th>
                                    <th scope="col" className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider">
                                      Attempt Number
                                    </th>
                                    <th scope="col" className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider">
                                      Score
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(courseTitlesMap[courseTitle]).map((lessonId) => {
                                    const submissions = courseTitlesMap[courseTitle][lessonId];
                                    const selectedAttempt = selectedAttempts[lessonId] || submissions[0].AttemptNumber;
                                    return (
                                      <tr key={lessonId} className="bg-white border-b">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                          {submissions.find(submission => submission.AttemptNumber === selectedAttempt)?.lessonId.title}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                          <select
                                            value={selectedAttempt}
                                            onChange={(e) => handleAttemptChange(lessonId, e)}
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                          >
                                            {submissions.map((submission) => (
                                              <option key={`${lessonId}-${submission.AttemptNumber}`} value={submission.AttemptNumber}>
                                                {submission.AttemptNumber}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {(() => {
                                            const selectedSubmission = submissions.find(submission => submission.AttemptNumber === selectedAttempt);
                                            if (!selectedSubmission) return 'N/A';
                                            const correctCount = selectedSubmission.questionAttempts.filter(attempt => attempt.QuestionStatus === 'correct').length;
                                            const totalCount = selectedSubmission.questionAttempts.length;
                                            return `${correctCount}/${totalCount} (${((correctCount / totalCount) * 100).toFixed(2)}%)`;
                                          })()}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ))
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default withRouter(QuizResults);