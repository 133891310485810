// import "./button.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import Button from "./button";

export const StyledCard = styled.div`
  background-color: #fff;
  color: #010b1d;
  border: ${(props) => (props.border ? props.border : "")} !important;

  /* &:hover {
    border: 5px solid #010b1d;
    cursor: pointer;
    transform: scale(1.1);
  } */
`;
