import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, del, handleError } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "600px",
    padding: "0px",
  },
};

const SingleCourse = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [course, setCourse] = useState({});
  const [activeLesson, setActiveLesson] = useState(null);
  const [loading2, setLoading] = useState(false);
  const [viewLessonModal, setViewLessonModal] = useState(false);

  const deleteCourse = async (id) => {
    try {
      setLoading(true);
      del(`courses/lessons/delete-lesson/${id}`, true);
      refetchData();
      return toast.success("Lesson deleted successfully");
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleDelete = (lesson) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this lesson",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCourse(lesson._id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const {
    isLoading: loading,
    error: coursesError,
    data: coursesData,
    refetch: refetchData,
  } = useQuery("fetchCourseDetails", () =>
    get(`/courses/${props.match.params.id}`, true).then((res) => {
      setCourse(res.data.course);
    })
  );

  const handleClick = (route) => {
    history.push(route);
  };

  const handleLessonClick = (route, lesson) => {
    history.push(route, {lesson});
  };

  return (
    <div className="dashboard__page ">
      <Header />

      <Modal
        isOpen={viewLessonModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setViewLessonModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <ReactPlayer
          className="w-full"
          width="100%"
          height="600px"
          controls
          url={activeLesson?.video}
        />
      </Modal>
      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 boxed__container top__wrapper block md:flex justify-between rounded-lg items-center">
          <main className="py-6">
            <p className="name capitalize">{course?.title}</p>
            <p className="others">{course?.description}</p>
          </main>

          <main className=" my-2 p-2 rounded-lg">
            <StyledButton
              onClick={() => {
                handleClick(`/course/${props.match.params.id}/create`);
              }}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
            >
              Create a New Lesson
            </StyledButton>
          </main>
        </main>
        <div className="my-12" />

        <main className="shadow rounded-xl mx-4 md:ml-4 md:mr-12">
          {loading ? <Skeleton className="mt-4" count={5} /> : ""}
        </main>

        {/* Show Courses if not loading, not fetching Subscripion and has subscription */}
        <main className="  md:ml-4 md:mr-12">
          {!loading ? (
            <main className="shadow mx-4 rounded-xl my-16 flex flex-wrap boxed__container top__wrapper ">
              {course &&
                course.lessons &&
                course.lessons.map((lesson, index) => (
                  <main key={index} className="w-full md:w-1/4 p-4">
                    <StyledCard
                      // onClick={() => {
                      //   handleClick(`/course/${course.id}`);
                      // }}
                      style={{
                        // background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 44.3%, #000000 125.99%), url(${lesson.image})`,
                        backgroundImage: `url(${lesson.image})`,
                        cursor: "pointer",
                      }}
                      className=" h-64 flex items-end w-ful text-white p-6 mx-2 rounded-2xl"
                    >
                      <main className="flex items-center justify-between w-full">
                        <main>
                          <p className="card__title">{lesson.title}</p>
                          {/* <p className="card__lessons">
                            {(course.lessons && course.lessons.length) || 0}{" "}
                            lessons
                          </p> */}
                        </main>
                        {/* <p className="card__duration">9</p> */}
                      </main>
                    </StyledCard>
                    <main className="flex justify-end mr-4 card__actions cursor-pointer">
                      <p
                        onClick={() => {
                          history.push(`/lesson/course/${lesson._id}`);
                        }}
                        className="m-1  text-blue-500"
                      >
                        Edit
                      </p>
                      <p
                        onClick={() => {
                          setActiveLesson(lesson);
                          setViewLessonModal(true);
                        }}
                        className="m-1  text-blue-500"
                      >
                        View
                      </p>
                      <p
                        onClick={() => {
                          history.push(
                            `/course/${props.match.params.id}/${lesson._id}/edit-assesment/`
                          );
                        }}
                        className="m-1  text-blue-500"
                      >
                        Assesments
                      </p>
                      <p
                        onClick={() => {
                          history.push(
                            `/course/${props.match.params.id}/${lesson._id}/manage-pdf/`
                          );
                        }}
                        className="m-1  text-blue-500"
                      >
                        PDFs
                      </p>                     
                       <p
                         onClick={() => {
                          handleLessonClick(
                            `/course/${props.match.params.id}/${lesson._id}/Quiz/`,
                            lesson
                          );
                        }}
                        className="m-1  text-blue-500"
                      >
                        Quiz
                      </p>                        
                       <p
                        className="m-1 text-red-500 cursor-pointer"
                        onClick={() => {
                          handleDelete(lesson);
                        }}
                      >
                        Delete
                      </p>
                    </main>
                  </main>
                ))}
            </main>
          ) : (
            ""
          )}
        </main>
      </main>
    </div>
  );
};

export default withRouter(SingleCourse);
