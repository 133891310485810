import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import { confirmAlert } from "react-confirm-alert"; // Import
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import { api, get, post, del, handleError } from "../../utils/api";
import "./index.scss";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQueries, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { StyledCard } from "./card.style";
const queryString = require("query-string");


const ShowCategory = (props) => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [view, setView] = useState("trend");
  const [loading, setLoading] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [exams, setExams] = useState([]);
  const [category, setCategory] = useState({});
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const [allThreadsCount, setAllThreadsCount] = useState(0);
  const [threadsCurrentPage, setThreadsCurrentPage] = useState(
    queryString.parse(props.location.search).page || 1
  );
  const [recentCurrentPage, setRecentCurrentPage] = useState(
    queryString.parse(props.location.search).page || 1
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const token = localStorage.getItem("maths-user");
  function openModal() {
    setIsOpen(true);
    setTitle("");
    setBody("");
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = (exam) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this exam",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteExam(exam.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteExam = async (id) => {
    try {
      setLoading(true);
      return del(`exams/${id}`, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };



  const {
    isLoading: loadingExams,
    error: examsError,
    data: errorData,
    refetch: refetchExams,
  } = useQuery("allExams", () =>
    get(`/exams/category/${props.match.params.id}/fetch-all`, true).then((res) => {

      setCategory(res.data.data?.category);
      setExams(res.data.data?.exams);
    })
  );

  const handleChange = (value) => {
    setBody(value);
  };

  return (
    <div className="forum_page ">
      {modalIsOpen || categoryModalIsOpen ? "" : <Header />}

      <main className="py-16 w-full ">
        <main className="boxed__container">
          <p className="forum__header pt-12 text-center">Manage Exams - {category.name}</p>
        </main>
        <div className="h-6" />

        <main className="flex flex-wrap boxed__container my-8">
          <main className="w-full">
            <main className="my-4 px-4 ml-4 ">
              <main className="flex justify-between flex-wrap">
                <span />

                <main className="flex space-x-3">
                  <StyledButton
                    onClick={() => history.push(`/category/${category.id}/update`)}
                    weight="normal"
                    size="12px"
                    padding="10px 5px"
                    backgroundColor="#286cff"
                  >
                    Edit Category
                  </StyledButton>
                  <StyledButton
                    onClick={() => history.push("/exams/create")}
                    weight="normal"
                    size="12px"
                    padding="10px 5px"
                    backgroundColor="#286cff"
                  >
                    Add New Exam
                  </StyledButton>
                </main>
              </main>
            </main>

            <main className="mx-4 md:ml-8 md:mr-4 -mt-2">
              {loadingExams ? (
                <Skeleton className="mt-4" count={5} />
              ) : (
                <main className="flex flex-wrap">
                  {exams.length > 0 ? exams.map((exam) => (
                    <main key={exam.id} className="py-2 w-full md:w-1/4"
                    // onClick={() => {
                    //   history.push(`/exams/update/${exam.id}`);
                    // }}            
                    >
                      <StyledCard
                        style={{
                          backgroundColor: '#000',
                          backgroundImage: `url(${exam.image})`,
                          cursor: "pointer",
                        }}
                        className=" h-64 flex items-end w-ful text-white p-6 mx-4 rounded-2xl"
                      >
                        <main className="flex items-center justify-between w-full">
                          <main>
                            <p className="card__title">{exam.name}</p>
                            {/* <p className="card__lessons">
                              {exam.category.name}
                            </p> */}
                          </main>
                        </main>
                      </StyledCard>
                      <main className="flex flex-wrap justify-between">
                        <section className="flex">
                          <p
                            onClick={() => {
                              history.push(`/exams/update/${exam.id}`);
                            }}
                            className="text-blue-500 cursor-pointer mr-2"
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              handleDelete(exam);
                            }}
                            className="text-red-500 cursor-pointer"
                          >
                            Delete
                          </p>
                        </section>
                        <p
                          onClick={() => {
                            history.push(`/exam/${exam.id}/manage-pdf`);
                          }}
                          className="text-blue-500 cursor-pointer mr-2"
                        >
                          Manage PDFs
                        </p>
                      </main>
                    </main>
                  )) : <p>No exam in category yet</p>}
                </main>
              )}
            </main>

            {/* <main className="flex flex-column justify-end pagination my-2">
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="inline mx-2 border px-2 pagination__bg"
                previousClassName="inline mx-2 border w-12 px-2 pagination__bg"
                activeClassName="bg-blue-300"
                nextClassName="inline mx-2 border px-2 pagination__bg"
                pageCount={allThreadsCount / 10}
                forcePage={threadsCurrentPage - 1}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                pageClassName="inline mx-2 border px-2 pagination__bg"
                onPageChange={handlePageClick}
                containerClassName="="
              />
            </main> */}
          </main>
        </main>
      </main>
    </div>
  );
};

export default ShowCategory;
