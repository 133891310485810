import React, { Component, useState, useContext } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import Sidebar from "../../components/Shared/Sidebar";

import { toast } from "react-toastify";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import * as moment from "moment";
import { AuthContext } from "../../contexts/AuthContext";

const Dashboard = (props) => {
  const token = localStorage.getItem("maths-user");
  const { user, setUser } = useContext(AuthContext);

  return (
    <div className="account__page ">
      <Header />
      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="profile" />
          </main>
          <main className="w-full md:w-4/5 border view__page">
            <main className="pt-8 w-full mx-auto md:w-3/5">
              <main className="flex justify-center mt-12">
                {user && user.profile_image ? (
                  <img
                    src={user.profile_image}
                    alt="User Profile Picture"
                    className="rounded-full mx-auto h-24 w-24 "
                  />
                ) : (
                  <p className="text-center">
                    <i className="fas fa-5x text-white fa-user-circle text-center mx-auto"></i>
                  </p>
                )}
              </main>
              <p className="user__header pt-8 text-left capitalize">
                {user.fullname}
              </p>
              <p className="user__subheader text-center py-2">
                {user.username}
              </p>
              <main className="flex justify-between mini my-4">
                <p>
                  Location{" "}
                  <span className="ml-2 blue__text">{user.location}</span>
                </p>
                <p>
                  Date Registered
                  <span className="ml-2 blue__text">
                    {moment(user.createdAt).format("Do MMMM YYYY")}
                  </span>
                </p>
              </main>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Dashboard;
