import React, {useState} from "react";
import { del, patch} from "../../../utils/api";
import { confirmAlert } from "react-confirm-alert";
import QuestionModal from "../QuestionModal";
import QuizModal from "../QuizModal";
import QuestionPage from "../QuestionPage";
import CreateQuizQuestion from "../../../views/Quiz/CreateQuizQuestion";
import CreateQuiz from "../../../views/Quiz/CreateQuiz";
import { toast } from "react-toastify";
import "./../ListQuizQuestions/ListQuizQuestions.scss";


function  DisplayQuizList({ 
    allQuizQuestions,
    quiz, 
    match ,  
    CreateModalOpen, 
    setCreateModalOpen, 
    refetchQuizQuestions,
    refetchQuizzes }) {

    const [EditQuizModalOpen, setEditQuizModalOpen] = useState(false);
    const [PreviewModalOpen, setPreviewModalOpen] = useState(false);
    const [EditModalOpen, setEditModalOpen] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [questionBankOpen, setQuestionBankOpen] = useState(false);


    
    const handleQuestionBankClick = () => {
        setQuestionBankOpen(prevState => !prevState);
    
    };

    const handleRemoveQuestionFromQuiz = (questionId) => {
        confirmAlert({
          title: 'Confirm to remove',
          message: 'Are you sure you want to remove this question from the quiz?',
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                try {
                  setLoading(true);
                  const response = await patch(`/quiz/${quiz._id}/remove-question/${questionId}`, {}, true);
                  if (response.status === 200) {
                    refetchQuizzes();
                    toast.success('Question removed from quiz successfully');
                  } else {
                    toast.error('Error occurred during removal');
                  }
                } catch (e) {
                  toast.error("An error occurred: " + e.message);
                } finally {
                  setLoading(false);
                }
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      };

    const handleDeleteQuiz = () => {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure you want to delete this quiz?',
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                try {
                  setLoading(true);
                  const response = await del(`/quiz/${quiz._id}`, true);
                  if (response.status === 200) {
                    refetchQuizzes();
                    toast.success('Quiz deleted successfully');
                  } else {
                    toast.error('Error occurred during deletion');
                  }
                } catch (e) {
                  toast.error("An error occurred: " + e.message);
                } finally {
                  setLoading(false);
                }
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      };

      const handlePublishQuiz = () => {
        confirmAlert({
          title: `Confirm to ${quiz.isPublished ? 'unpublish' : 'publish'} quiz`,
          message: `Are you sure you want to ${quiz.isPublished ? 'unpublish' : 'publish'} this quiz?`,
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                try {
                  setLoading(true);
                  const response = await patch(`/quiz/${quiz._id}/publish`, { publish: !quiz.isPublished }, true);
                  if (response.status === 200) {
                    refetchQuizzes();
                    toast.success(`Quiz ${quiz.isPublished ? 'unpublished' : 'published'} successfully`);
                  } else {
                    toast.error(`Error occurred during ${quiz.isPublished ? 'unpublishing' : 'publishing'}`);
                  }
                } catch (e) {
                  toast.error("An error occurred: " + e.message);
                } finally {
                  setLoading(false);
                }
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      };

    return (
        <div className="w-full flex flex-col items-stretch">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="section-container w-full">
                        <div>
                    <span class="font-bold text-2xl"> { quiz.Name }</span>
                    <button onClick={() => { handleQuestionBankClick();  }} className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out" style={{ marginLeft: '30px' }}>
                        { questionBankOpen ? "Close Quiz" : "See Quiz" }
                    </button>
                        <button 
                          onClick={() => { handlePublishQuiz(); }} 
                          className={`ml-4 px-4 py-2 text-white rounded-lg shadow-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-150 ease-in-out ${
                            quiz.isPublished ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500' : 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                          }`} 
                          style={{ marginLeft: '30px' }}>
                          { quiz.isPublished ? "Unpublish Quiz" : "Publish Quiz" }
                        </button>
                       <button onClick={() => { handleDeleteQuiz();  }} className="ml-4 px-4 py-2  text-white rounded-lg shadow-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out" style={{ marginLeft: '30px' }}>
                       Delete Quiz
                       </button>
                       <button onClick={() => { setEditQuizModalOpen(true); }} className="ml-4 px-4 py-2  text-white rounded-lg shadow-md bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out" style={{ marginLeft: '30px' }}>
                       Edit Quiz
                       </button>
                    </div>
                    {Array.isArray(quiz.questions) ? (
                        quiz.questions.length > 0 ? (
                            questionBankOpen ? (
                                quiz.questions.map((question) => (
                                    <div key={question._id} className="flex justify-between items-center mb-4 w-full space-x-4">
                                        <div className="flex items-center space-x-4 flex-1 border-2 border-gray-300 p-2 rounded">
                                            <span className="text-lg text-black" style={{ whiteSpace: 'pre-line'}}>{question.question}</span>
                                            {question.image && question.image.trim() !== '' && (
                                                <img src={question.image} alt="Question Image" className="h-auto object-contain" style={{ width: "50%" }} />
                                            )}
                                        </div>
                                        <div className="flex space-x-2">
                                            <button onClick={() => { setCurrentQuestion(question); setEditModalOpen(true); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-yellow-500 hover:bg-yellow-700 rounded-lg focus:outline-none">Edit Question</button>
                                            <button onClick={() => { setCurrentQuestion(question); setPreviewModalOpen(true); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-blue-500 hover:bg-blue-700 rounded-lg focus:outline-none">Preview Question</button>
                                            <button onClick={() => { handleRemoveQuestionFromQuiz(question._id); }} className="whitespace-nowrap h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none">
                                                Remove Question
                                            </button>                                                                    
                                        </div>
                                    </div>
                                ))
                            ) : null
                        ) : (
                            <div>No quiz questions found.</div>
                        )
                    ) : (
                        <div>No quiz questions found.</div>
                    )}
                    <QuizModal isOpen={EditQuizModalOpen} onClose={() => setEditQuizModalOpen(false)}>
                        <CreateQuiz EditMode = {true}  match = {match} inputQuiz = {quiz} allQuizQuestions = {allQuizQuestions} isQuizModalOpen = {setEditQuizModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuizModal>
                    <QuestionModal isOpen={EditModalOpen} onClose={() => setEditModalOpen(false)}>
                        <CreateQuizQuestion inputQuizQuestion={currentQuestion} match={match} isEditModalOpen = {setEditModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuestionModal>
                    <QuestionModal isOpen={PreviewModalOpen} onClose={() => setPreviewModalOpen(false)}>
                        <QuestionPage quizQuestion={currentQuestion} />
                    </QuestionModal>
                    <QuestionModal isOpen={CreateModalOpen} onClose={() => setCreateModalOpen(false)}>
                        <CreateQuizQuestion match={match} isCreateModalOpen = {setCreateModalOpen} refetchQuizQuestions={refetchQuizQuestions} refetchQuizzes={refetchQuizzes} />
                    </QuestionModal>
                </div>
            )}
        </div>
    );
}

export default DisplayQuizList;