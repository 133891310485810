import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import Sidebar from "../../components/Shared/Sidebar";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put } from "../../utils/api";
import { toast } from "react-toastify";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";

const Dashboard = (props) => {
  const token = localStorage.getItem("maths-user");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ]);

  const {
    isLoading: loadingData,
    error,
    data,
    refetch,
  } = useQuery("formumData", () =>
    get(`users/profile`, true).then((res) => {
      setUser(res.data.user);
    })
  );

  const { mutate, isLoading } = useMutation(
    (payload) => updateProfile(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        toast.success("Profile Update Successful");
        setLoading(false);
      },
    }
  );

  const updateProfile = async (data) => {
    try {
      setLoading(true);
      return put("/users/profile/update", data, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleChange = async (e, key) => {
    e.preventDefault();
    setUser({ ...user, [key]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const { firstname, lastname, location } = user;
    mutate({ firstname, lastname, location });
  };

  return (
    <div className="account__page ">
      <Header />
      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="account" />
          </main>
          <main className="w-full md:w-4/5 border">
            <main className="mx-auto">
              <main className="w-full mx-auto md:w-1/2 my-2 px-2">
                <p className="my-8"></p>
                <p className="text-center page__header">Account</p>
                <p className="text-center sub__header">
                  Edit your account settings and change password here
                </p>
                <p className="my-8"></p>

                <main className="my-2">
                  <p className="form__label">First Name</p>
                  <input
                    type="text"
                    placeholder="e.g Ademola"
                    value={user.firstname}
                    onChange={(e) => handleChange(e, "firstname")}
                    className={`form__border w-full my-2 `}
                  />
                </main>

                <main className="my-2">
                  <p className="form__label">Last Name</p>
                  <input
                    type="text"
                    placeholder="e.g Ademola"
                    value={user.lastname}
                    onChange={(e) => handleChange(e, "lastname")}
                    className={`form__border w-full my-2 `}
                  />
                </main>

                <main className="w-full ">
                  <p className="form__label">
                    Location<span className="text-red-500">*</span>
                  </p>
                  <select
                    value={user.location}
                    className="form__border w-full my-2 "
                    onChange={(e) => handleChange(e, "location")}
                  >
                    <option>Select an option</option>
                    {states.map((sta, i) => {
                      return (
                        <option key={i} value={sta}>
                          {sta}
                        </option>
                      );
                    })}
                  </select>
                </main>

                <main className="w-full flex justify-end my-2 px-2">
                  <StyledButton
                    onClick={(e) => handleClick(e)}
                    borderRadius="3px"
                    width="200px"
                    backgroundColor="#286cff"
                    type="submit"
                  >
                    {loading ? <Loader /> : "Update Profile"}
                  </StyledButton>
                </main>
              </main>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Dashboard;
