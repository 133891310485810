import React, { Component, useState } from "react";
import "./back.scss";
import { useHistory } from "react-router-dom";

const BackButton = (props) => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };
  return (
    <main className="mt-6">
      <main className="back__button flex ">
        <main
          onClick={() => {
            handleClick();
          }}
          className="button__wrapper w-12 h-12 rounded-full cursor-pointer  p-2"
        >
          <i className="fas fa-long-arrow-alt-left fa-2x"></i>
        </main>
      </main>
    </main>
  );
};
export default BackButton;
