import "./pricing.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button";
import starter from "../../../img/starter.svg";
import basic from "../../../img/basic.svg";
import professional from "../../../img/professional.svg";

import { StyledHeader } from "./pricing-header-style";
import { StyledButton } from "../../Shared/Button/button.style";
import { StyledCard } from "./pricing-card-style";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [
        {
          name: "Basic Plan",
          amount: "£100",
          image: basic,
          color: "#009c2a",
          hasBorder: false,
          content: [
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
          ],
        },
        {
          name: "Starter Plan",
          amount: "£300",
          color: "#FFAA00",
          image: starter,
          hasBorder: true,
          content: [
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
          ],
        },
        {
          name: "Professional Plan",
          amount: "£500",
          image: professional,
          color: "#9C0025",
          hasBorder: false,
          content: [
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
          ],
        },
      ],
    };
  }

  render() {
    const { subscriptions } = this.state;
    return (
      <main className="mb-8 pricing">
        <div className="wrapper  pt-8 pb-32">
          <main className="boxed__container pb-32">
            <p className="mini__header mx-auto w-full text-center">
              Pricing and Subscription
            </p>
            <p className="header mx-auto w-full text-center">
              Choose a Plan that works for you
            </p>
            <p className="content text-center mx-auto w-full">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore
            </p>
          </main>
        </div>
        <main className="boxed__container move__top">
          <main className="flex flex-wrap subscription justify-center w-full  pt-8">
            {subscriptions.map((subscription, index) => (
              <div key={index} className="w-full md:w-1/3 text-center px-4 ">
                <StyledCard
                  border={subscription.hasBorder ? "5px solid #010b1d;" : ""}
                  className=" rounded-xl shadow-2xl my-2 md:my-0 p-8 "
                >
                  <main className="flex flex-wrap items-center">
                    <img className="h-24" src={subscription.image} />
                    <main className="ml-6 block">
                      <StyledHeader color={subscription.color}>
                        {subscription.name}
                      </StyledHeader>
                      {/* <p className="card__header">{subscription.name}</p> */}
                      <span className="card__price my-2">
                        {subscription.amount}
                      </span>
                      <span className="user">/user</span>
                    </main>
                  </main>
                  <ul className="my-12">
                    {subscription.content.map((text, i) => (
                      <li className="list ml-0 md:ml-8 my-3" key={i}>
                        {text}
                      </li>
                    ))}
                  </ul>
                  <StyledButton width="100%" backgroundColor="#286CFF">
                    Choose Plan
                  </StyledButton>
                </StyledCard>
              </div>
            ))}
          </main>
        </main>
      </main>
    );
  }
}
