import React, { Component, useState } from "react";
import "./sidemenu.scss";
import { useHistory } from "react-router-dom";
import play from "../../../img/blue-play.svg";
import SideMenuItem from "./menu-item";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { post } from "../../../utils/api";

const CourseSideMenu = (props) => {
  const route = props.route || "courses";
  const handleClick = (lesson, type, index) => {
    props.onClickItem(lesson, type, index);
  };

  const handleSelected = (value, lesson) => {
    if (value) {
      mutate(lesson._id);
    }
  };

  const { mutate, isLoading } = useMutation(
    (payload) => markCourseComplete(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
      },
      onSuccess: (data, variables, context) => {
        toast.success("Lesson completed");
      },
    }
  );

  const markCourseComplete = async (lessonId) => {
    try {
      return await post(`/${route}/lessons/${lessonId}/completed`, {}, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  return (
    <div className="course__sidemenu">
      {props &&
        props.lessons &&
        props.lessons.length &&
        props.lessons.map((lesson, index) => (
          <main
            key={index}
            // className={props.activeCourseIndex === index ? "bg-red-400" : ""}
            className="w-full p-4 border"
          >
            <SideMenuItem
              selectCourseItem={(lesson, type, index) => {
                handleClick(lesson, type, index);
              }}
              markCompleted={(e, lesson, type, index) => {
                handleSelected(e, lesson, type, index);
              }}
              lesson={lesson}
            />
          </main>
        ))}
    </div>
  );
};
export default CourseSideMenu;
