import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import TrendCard from "../../components/Forum/Trend";
import BackButton from "../../components/Forum/Back";
import CommentCard from "../../components/Forum/Comment";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import sam from "../../img/sam.png";
import { Link, useHistory } from "react-router-dom";
import ReactQuill from "react-quill";

const Post = (props) => {
  const history = useHistory();
  const {
    isLoading: loadingData,
    error,
    data,
    refetch,
  } = useQuery("formumPost", () =>
    get(`forums/${props.match.params.postId}/fetch-thread`, true).then(
      (res) => res.data.thread
    )
  );
  const token = localStorage.getItem("maths-user");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    mutate({ comment });
  };

  const handleClick = (route) => {
    history.push(route);
  };

  const { mutate, isLoading: createLoader } = useMutation(
    (payload) => createComment(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setLoading(false);
        if (data && data.status < 400) {
          setLoading(false);
          setComment("");
          refetch();
          toast.success("Comment created Successful");
        }
      },
    }
  );

  const createComment = async (data) => {
    try {
      setLoading(true);
      const res = await post(
        `forums/${props.match.params.postId}/post-comment`,
        data,
        true
      );
      setLoading(false);
      refetch();
      return res;
    } catch (e) {
      setLoading(false);
      const message = await handleError(e);
      toast.error(message);
    }
  };

  const handleChange = (value) => {
    setComment(value);
  };

  return (
    <div className="post_page ">
      <Header />

      <main className="py-24 w-full ">
        <main className="boxed__container w-full md:w-1/2 ">
          <main className="limit__width">
            {loadingData ? (
              <main className="mt-8">
                <Skeleton count={5} />
              </main>
            ) : (
              <main>
                <p></p>
                <BackButton className="pt-8" />
                <p className="post__header pt-4 text-left">{data.title} </p>
                <p
                  className="post__subheader text-center py-2"
                  dangerouslySetInnerHTML={{
                    __html: data.body,
                  }}
                ></p>

                <main
                  onClick={() => {
                    handleClick(`/user/${data.user.id}`);
                  }}
                  className="flex my-8 w-full cursor-pointer"
                >
                  {data && data.user && data.user.profile_image ? (
                    <img
                      src={data.user.profile_image}
                      alt="User Profile Picture"
                      className="rounded-full  h-8 w-8 "
                    />
                  ) : (
                    <p className="text-center">
                      <i className="fas text-black fa-user-circle text-center "></i>
                    </p>
                  )}
                  {/* <img src={sam} className="h-8" /> */}
                  <p className="posted ml-3 mt-1">
                    <span className="poster cursor-pointer">
                      {data && data.user && data.user.fullname}
                    </span>
                  </p>
                </main>

                <main className="flex justify-end my-4">
                  {token ? (
                    <main className="w-full">
                      <main className="flex justify-center w-full">
                        <ReactQuill
                          className=" w-full mb-8 mx-auto h-32"
                          value={comment}
                          onChange={handleChange}
                        />
                        {/* <textarea
                          type="text"
                          className="form__border w-full my-2 mx-auto"
                          placeholder="Post a comment"
                          rows="4"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        /> */}
                      </main>
                      <StyledButton
                        className="mt-6"
                        weight="normal"
                        size="12px"
                        padding="10px 5px"
                        backgroundColor="#286cff"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {loading ? <Loader /> : " Post a Comment"}
                      </StyledButton>
                    </main>
                  ) : (
                    <p className="posted">
                      Pleasex
                      <Link className="isLink" to="/login">
                        {" "}
                        login{" "}
                      </Link>
                      to comment
                    </p>
                  )}
                </main>

                <main className="my-8">
                  {data && data.comments && data.comments.length ? (
                    data.comments.map((comment, index) => (
                      <main className="py-2">
                        <CommentCard comment={comment} key={index} />
                        <hr className="mx-4" />
                      </main>
                    ))
                  ) : (
                    <p>No comments</p>
                  )}
                </main>
              </main>
            )}
          </main>
        </main>
      </main>
    </div>
  );
};

export default Post;
