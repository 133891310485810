import "./header.scss";
import React, { Component, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { StyledButton } from "../../Shared/Button/button.style";
import { withRouter } from "react-router";
// import { AuthContext } from "../../contexts/AuthContext";
import logo  from '../../../img/logo.png';

const Header = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [routes, setRoutes] = useState([
    { name: "About Us", route: "/about" },
    { name: "Forum", route: "/forum" },
    { name: "FAQ", route: "/faqs" },
    { name: "Contact Us", route: "/contact" },
  ]);

  function toggle() {
    setOpen(!open);
  }

  function handleClick(route) {
    history.push(route);
  }
  const token = localStorage.getItem("maths-user");

  return (
    <div>
      <div className="w-full header fixed z-10 bg-white py-4 shadow" id="">
        <nav className="boxed__container w-full z-50  flex items-center justify-between flex-wrap py-2 ">
          <div className="flex items-center flex-no-shrink text-white ">
            <Link to="/">
              <div className="flex items-center flex-no-shrink text-black ">
                <p className="link__text"><img src={logo} alt="Next Gen Learners" width="135"/></p>
              </div>
            </Link>
          </div>
          <div className="block lg:hidden">
            <button
              onClick={toggle}
              className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={
              open
                ? "block w-full flex-grow lg:flex sm:items-center lg:w-auto"
                : "hidden w-full flex-grow lg:flex sm:items-center lg:w-auto"
            }
          >
            {/* <div className=""> */}
            <div className="text-sm lg:flex-grow"></div>
            {/* <div> */}
            {routes.map((route, index) => (
              <Link
                key={index}
                to={route.route}
                className="link__text block paragraph lg:inline-block mt-4 lg:mt-0 mr-6"
              >
                {route.name}
              </Link>
            ))}
            {token ? (
              <StyledButton
                onClick={() => {
                  handleClick("/dashboard");
                }}
                backgroundColor="#286cff"
              >
                Dashboard
              </StyledButton>
            ) : (
              <StyledButton
                onClick={() => {
                  handleClick("/login");
                }}
                backgroundColor="#286cff"
              >
                Login
              </StyledButton>
            )}
            {/* </div> */}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
