import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "600px",
    padding: "0px",
  },
};

const CreateLesson = (props) => {
  const history = useHistory();
  const questionFormat = {
    text: "",
    score: 0,
    type: "quiz",
    image: "",
    question_options: [
      {
        text: "",
        image: "",
        isCorrect: false,
        reason: "",
      },
      {
        text: "",
        image: "",
        isCorrect: false,
        reason: "",
      },
      {
        text: "",
        image: "",
        isCorrect: false,
        reason: "",
      },
      {
        text: "",
        image: "",
        isCorrect: false,
        reason: "",
      },
    ],
  };
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [assesment, setAssesment] = useState({
    assessment_questions: [questionFormat],
  });

  const handleAssesmentChange = (e, index) => {
    const { name, value } = e.target;
    const temp = assesment.assessment_questions;

    temp[index][name] = name == "score" ? parseInt(value) : value;
    setAssesment({
      ...assesment,
      assessment_questions: temp,
    });
  };

  const {
    isLoading: fetchLoading,
    error: coursesError,
    data: coursesData,
    refetch: refetchData,
  } = useQuery("fetchSingleLessonDetail", () =>
    get(`/challenges/lessons/${props.match.params.lesson}`, true).then(
      (res) => {
        setCourse(res.data.course);
        if (course && course.assessments && course.assessments.length) {
          setAssesment(course.assessments[0]);
        }
      }
    )
  );

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value } = e.target;
    const temp = assesment.assessment_questions;
    const tempOptions = temp[questionIndex]["question_options"];
    tempOptions[optionIndex][name] = value;
    temp[questionIndex]["question_options"] = tempOptions;
    setAssesment({
      ...assesment,
      assessment_questions: temp,
    });
  };

  const addQuestion = (e) => {
    setAssesment({
      ...assesment,
      assessment_questions: [...assesment.assessment_questions, questionFormat],
    });
  };

  const createAssesment = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await post(
        `/challenges/lessons/${props.match.params.lesson}/assessments/create`,
        {
          ...assesment,
          duration: parseInt(assesment.duration),
          isPublished: true,
        },
        true
      );
      toast.success("Assesment created successfully");
      return history.push(`/challenge/${props.match.params.id}`);
    } catch (e) {
      setLoading(false);
      const message = handleError(e);
      toast.error(message);
      return e;
    }
  };
  return (
    <div className="course__page ">
      <Header />

      <div className="py-24" />
      <main className="boxed__container">
        <main className="w-full md:w-3/5 mx-auto">
          <p>Add Assesment</p>
          <main className="my-2">
            <p className="form__label">Assesment Title</p>
            <input
              type="text"
              placeholder="e.g Introduction to Mathematics"
              value={assesment.title}
              onChange={(e) =>
                setAssesment({ ...assesment, title: e.target.value })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Assesment Description</p>
            <input
              type="text"
              placeholder="What is this assesment about"
              value={assesment.description}
              onChange={(e) =>
                setAssesment({
                  ...assesment,
                  description: e.target.value,
                })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Assesment Duration</p>
            <input
              type="number"
              placeholder="What is length of the assesment"
              value={assesment.duration}
              onChange={(e) =>
                setAssesment({ ...assesment, duration: e.target.value })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <main className="my-2">
            <p className="form__label">Assesment Score</p>
            <input
              type="number"
              placeholder="What is total score of the assesment"
              value={assesment.score}
              onChange={(e) =>
                setAssesment({ ...assesment, score: parseInt(e.target.value) })
              }
              className={`form__border w-full my-2 `}
            />
          </main>

          <StyledButton
            onClick={(e) => {
              addQuestion(e);
            }}
            borderRadius="3px"
            width="200px"
            padding="10px 5px"
            backgroundColor="#286cff"
            className="mx-auto"
          >
            Add Question
          </StyledButton>

          {assesment &&
            assesment.assessment_questions &&
            assesment.assessment_questions.map((asses, index) => (
              <main key={index} className="w-full  p-4">
                <main className="my-2">
                  <main className="flex">
                    <main className="w-full md:w-1/2 px-2">
                      <p className="form__label">Question Text</p>
                      <input
                        name="text"
                        type="text"
                        placeholder="e.g Introduction to Mathematics"
                        onChange={(e) => handleAssesmentChange(e, index)}
                        className={`form__border w-full my-2 `}
                      />
                    </main>

                    <main className="w-full md:w-1/2 px-2">
                      <p className="form__label">Question Score</p>
                      <input
                        name="score"
                        type="number"
                        placeholder="e.g Introduction to Mathematics"
                        onChange={(e) => handleAssesmentChange(e, index)}
                        className={`form__border w-full my-2 `}
                      />
                    </main>
                  </main>

                  <main className="flex flex-wrap">
                    {asses.question_options.map((option, optionIndex) => (
                      <main key={optionIndex} className="w-1/2">
                        <p>Option {optionIndex + 1}</p>
                        <main className="my-2 px-2">
                          <p className="form__label">Option Text</p>
                          <input
                            name="text"
                            type="text"
                            placeholder="e.g Introduction to Mathematics"
                            onChange={(e) =>
                              handleOptionChange(e, index, optionIndex)
                            }
                            className={`form__border w-full my-2 `}
                          />

                          <main className="flex">
                            <label>Is this the correct answer</label>
                            <input
                              name="isCorrect"
                              type="radio"
                              value={true}
                              placeholder="e.g Introduction to Mathematics"
                              onChange={(e) =>
                                handleOptionChange(e, index, optionIndex)
                              }
                              className={`form__border ml-2 my-2 `}
                            />
                            Yes
                            {/* <input
                              name="isCorrect"
                              type="radio"
                              value={false}
                              placeholder="e.g Introduction to Mathematics"
                              onChange={(e) =>
                                handleOptionChange(e, index, optionIndex)
                              }
                              className={`form__border ml-2 my-2 `}
                            />
                            No */}
                          </main>
                          <p className="form__label">Reason</p>
                          <input
                            name="reason"
                            type="text"
                            placeholder="e.g Introduction to Mathematics"
                            onChange={(e) =>
                              handleOptionChange(e, index, optionIndex)
                            }
                            className={`form__border w-full my-2 `}
                          />
                        </main>
                      </main>
                    ))}
                  </main>
                </main>
              </main>
            ))}

          <main className="flex w-full ">
            <StyledButton
              onClick={(e) => {
                createAssesment(e);
              }}
              borderRadius="3px"
              width="75px"
              padding="10px 5px"
              backgroundColor="#286cff"
              className="mx-auto"
            >
              {loading ? <Loader /> : "Save"}
            </StyledButton>
          </main>
        </main>
      </main>
    </div>
  );
};

export default CreateLesson;
