import React, { useEffect } from "react";

function FreeTextForm({ inputs, setInputs, updateAnswers, setSelectedAnswer}) {

   useEffect(() => {
        setSelectedAnswer('N/A');
        updateAnswers(inputs, 'N/A');

      }, []); 

    const handleAddInput = () => {
        const newInput = {text: '', id: Date.now().toString() };
        const newInputs = [...inputs, newInput];
        setInputs(newInputs);
        updateAnswers(newInputs, 'N/A');
    };

    const handleRemoveInput = (id) => {
        const newInputs = inputs.filter(input => input.id !== id);
        setInputs(newInputs);
        updateAnswers(newInputs, 'N/A');
    };

    const handleChangeInput = (text, id) => {
        const newInputs = inputs.map(input => input.id === id ? {...input, text } : input );
        setInputs(newInputs);
        updateAnswers(newInputs, 'N/A');
    };




    return(

        <div>
            <button onClick={handleAddInput} className= 'h-12 px-4 text-lg text-black bg-blue-200 hover:bg-blue-500 rounded-lg focus:outline-none whitespace-nowrap inline-block'> Add Free Text Answer Option</button>
            {inputs.map(input => (
                <div key={input.id} className="flex items-center space-x-4 my-2">
                    <input
                        type = "text"
                        value= {input.text}
                        onChange = {(e) => handleChangeInput(e.target.value, input.id)}
                        className= "w-full h-12 p-3 text-lg border-2 border-gray-600 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    <button onClick= {() => handleRemoveInput(input.id)} className ='h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none'>Remove</button>
                </div>
            ))}

        </div>
    );


}

export default FreeTextForm;