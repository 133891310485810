import React, { Component, useState, useContext } from "react";
import Header from "../../components/Shared/Header";
import TrendCard from "../../components/Forum/Trend";
import ThreadCard from "../../components/Forum/Thread";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import { api, get, post } from "../../utils/api";
import "./index.scss";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQueries, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import ReactPaginate from "react-paginate";
import ChatPreview from "../../components/Chat/Preview";
import ChatBubble from "../../components/Chat/Bubble";
import sam from "../../img/sam.png";
import { AuthContext } from "../../contexts/AuthContext";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { data } from "autoprefixer";

const Forum = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [allChatMessages, setAllChatMessages] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [message, setMessage] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [isNewChat, setIsNewChat] = useState(true);

  const [isActive, setIsActive] = useState(false);
  const user = JSON.parse(localStorage.getItem("maths-app-user"));

  const token = localStorage.getItem("maths-user");
  configureAbly({
    key: process.env.REACT_APP_ABLY_KEY,
  });

  const {
    isLoading,
    error: chatFetchingError,
    data: chatsData,
    refetch,
  } = useQuery("allChats", () =>
    get(`/chats/fetch-chat-lists?username=${searchParam}`, true).then((res) => {
      setAllChats(res.data.chat_list);
    })
  );
  const [channel] = useChannel(activeChat?._id, (message) => {
    setAllChatMessages([
      ...allChatMessages,
      { message: message.data.message, sender: message.data.sender },
    ]);
  });

  const {
    isLoading: loadingMessages,
    error: loadingMessagesError,
    data: messagesData,
    refetch: fetchChatMessages,
  } = useQuery("allChatMessagesFetched", () =>
    get(`/chats/${activeChat._id}/fetch-messages`, true).then((res) => {
      setAllChatMessages(res.data.channel_messages);
      setIsActive(true);
      setLoading(false);
    })
  );

  const { mutate, isLoading: createLoader } = useMutation(
    (payload) => sendMessage(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setMessage("");
      },
    }
  );

  const sendMessage = async (data) => {
    try {
      //setLoading(true);
      setMessage("");
      const res = await post("/chats/send-message", data, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleSendMessage = (e) => {
    setMessage("");
    const mess = message;
    e.preventDefault();
    mutate({
      receiverID: activeChat.receiver.user_id,
      channel_id: activeChat._id,
      message: mess,
    });
  };

  const handleChangeChat = (chat) => {
    setLoading(true);
    setIsActive(true);
    setActiveChat(chat);
    setTimeout(function () {
      fetchChatMessages();
    }, 500);
  };

  return (
    <div className="forum_page ">
      <Header />

      {isLoading ? <Skeleton className="mt-4" count={5} /> : ""}
      {!isLoading && (
        <main className="py-16 w-full ">
          <main className="flex flex-wrap boxed__container my-8">
            <main className="w-full md:w-1/3 left__container shadow-xl">
              <main className="bg-0 shadow-lg  ">
                <main className="mx-auto px-4">
                  <input
                    type="search"
                    name="q"
                    className="ml-3 form__border w-full  my-2 mx-8 text-sm text-white rounded-md pl-10 focus:outline-none bg-white text-gray-900"
                    placeholder=" Search"
                    autocomplete="off"
                    onChange={(e) => {
                      setSearchParam(e.target.value);
                      setTimeout(function () {
                        refetch();
                      }, 500);
                    }}
                  />
                  <p className="trending my-4">Messages</p>

                  {allChats &&
                    allChats.map((chat) => (
                      <div
                        onClick={(e) => handleChangeChat(chat)}
                        key={chat._id}
                        className="w-full"
                      >
                        <ChatPreview chat={chat} />
                        <hr className="mx-4" />
                      </div>
                    ))}
                </main>
              </main>
            </main>

            <main className="w-full md:w-2/3">
              <main className="w-full ">
                {loading ? (
                  <Skeleton className="mt-4" count={5} />
                ) : allChats && allChats.length < 1 ? (
                  <main className="py-32">
                    <p className="top__text text-center pt-16">
                      You have no active chat
                    </p>
                    <section className="w-full flex justify-center my-3"></section>
                  </main>
                ) : activeChat ? (
                  <main className="w-full">
                    <main className="flex items-center align-center w-full">
                      {activeChat &&
                      activeChat.receiver &&
                      activeChat.receiver.profile_picture ? (
                        <img
                          src={activeChat.receiver.profile_picture}
                          alt="User Profile Picture"
                          className="rounded-full  h-12 w-12 "
                        />
                      ) : (
                        <p className="text-center">
                          <i className="fas fa-2x text-black fa-user-circle text-center mx-auto"></i>
                        </p>
                      )}

                      <p className="posted ml-2 ">
                        <span className="block name trending">
                          {activeChat?.receiver.fullname}
                        </span>
                      </p>
                    </main>
                    <hr className="my-2" />
                    <div className="chat__container">
                      {allChatMessages &&
                        allChatMessages.map((chat) => (
                          <div key={chat._id} className="w-full">
                            <ChatBubble chat={chat} />
                          </div>
                        ))}
                    </div>
                    <div className="position-sticky bottom-0 z-index-1 bg-white shadow-lg w-full flex">
                      <input
                        type="search"
                        name="q"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="ml-3 w-full form__border my-2 mx-8 text-sm text-white rounded-md pl-10 focus:outline-none bg-white text-gray-900"
                        placeholder="Type a Message"
                        autocomplete="off"
                      />
                      <StyledButton
                        type="submit"
                        borderRadius="5px"
                        width="50px"
                        backgroundColor="#286cff"
                        onClick={(e) => {
                          handleSendMessage(e);
                        }}
                      >
                        <i class="fas fa-paper-plane"></i>
                      </StyledButton>
                    </div>
                  </main>
                ) : (
                  <main className="py-32">
                    <p className="top__text text-center pt-16">
                      Please select a chat
                    </p>
                    <section className="w-full flex justify-center my-3"></section>
                  </main>
                )}
              </main>
            </main>
          </main>
        </main>
      )}

      {/* <main className="mx-auto ">
        {!isLoading && allChats.length < 1 ? (
          <main className="py-32">
            <p className="top__text text-center pt-16">
              You have no active chat
            </p>
            <section className="w-full flex justify-center my-3"></section>
          </main>
        ) : (
          ""
        )}
      </main> */}
    </div>
  );
};

export default Forum;
