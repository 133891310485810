import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../../components/Shared/Header";
import { StyledCard } from "../card.style";
import "../index.scss";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, del, handleError, patch } from "../../../utils/api";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../../components/Shared/Button/button.style";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

import Modal from "react-modal";
import List from "./list";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "600px",
    padding: "0px",
  },
};

const ManageExamPDF = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [pdfs, setPDFs] = useState([]);
  const [lesson, setLesson] = useState({});
  const [activeLesson, setActiveLesson] = useState(null);
  const [loading2, setLoading] = useState(false);
  const [viewLessonModal, setViewLessonModal] = useState(false);

  const deletePDF = async (pdfIndex) => {
    console.log({pdfIndex})

    const _pdfs = pdfs;

    try {
      setLoading(true);
      _pdfs.splice(pdfIndex, 1);


      patch(`/exams/${props.match.params.id}/pdf/update`, {pdfs: _pdfs}, true).then(res => {
        if(res.data.status == "success") {
          setPDFs(_pdfs);
//          refetchData();
          return toast.success("PDF deleted successfully");  
        }

      }).catch(e => {
        return toast.error("Error deleting PDF");  
      });
      
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleDelete = (pdfIndex) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this PDF?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deletePDF(pdfIndex);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleEdit = (pdf) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this PDF?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deletePDF(pdf);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const {
    isLoading: loading,
    error: pdfError,
    data: pdfsData,
    refetch: refetchData,
  } = useQuery("fetchPDFDetails", () =>
    get( `exams/${props.match.params.id}/`, true).then((res) => {
      const { id, title, pdfs } = res.data.data;

      setPDFs(pdfs);
      setLesson({id, title});
    })
  );

  const handleClick = (route) => {
    console.log({route})
    history.push(route);
  };

  return (
    <div className="dashboard__page ">
      <Header />

      <Modal
        isOpen={viewLessonModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setViewLessonModal(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        // className="my__modal"
      >
        <ReactPlayer
          className="w-full"
          width="100%"
          height="600px"
          controls
          url={activeLesson?.video}
        />
      </Modal>
      <div className="py-8" />
      <main className="py-16 w-full ">
        <main className=" my-16 boxed__container top__wrapper block md:flex justify-between rounded-lg items-center">
          <main className="py-6">
            <p className="name capitalize">{lesson?.title}</p>
          </main>

          <main className=" my-2 p-2 rounded-lg">
            <StyledButton
              onClick={() => {
                // handleClick(`/course/${props.match.params.id}/create`);
                handleClick(`/exam/${props.match.params.id}/add-pdf/`);
              }}
              backgroundColor="#286cff"
              color="#fff"
              width="250px"
            >
              Add New PDF
            </StyledButton>
          </main>
        </main>
        <div className="my-12" />

        <main className="shadow rounded-xl mx-4 md:ml-4 md:mr-12">
          {loading ? <Skeleton className="mt-4" count={5} /> : ""}
        </main>

        <main className="  md:ml-4 md:mr-12">
          {!loading ? (
            <List 
                items={pdfs} 
                lesson={lesson} 
                examId={props.match.params.id}
                onDelete={handleDelete}
                onEdit={handleEdit}
                />
            // <main className="shadow mx-4 rounded-xl my-16 flex flex-wrap boxed__container top__wrapper ">
            //   {pdfs.length > 0 &&
            //     pdfs.map((pdf, index) => (
            //       <main key={index} className="w-full md:w-1/4 p-4">
            //         <StyledCard
            //           // onClick={() => {
            //           //   handleClick(`/course/${course.id}`);
            //           // }}
            //           style={{
            //             // background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 44.3%, #000000 125.99%), url(${lesson.image})`,
            //             backgroundImage: `url(${pdf.image})`,
            //             cursor: "pointer",
            //           }}
            //           className=" h-64 flex items-end w-ful text-white p-6 mx-2 rounded-2xl"
            //         >
            //           <main className="flex items-center justify-between w-full">
            //             <main>
            //               <p className="card__title">{pdf.title}</p>
            //               {/* <p className="card__lessons">
            //                 {(course.lessons && course.lessons.length) || 0}{" "}
            //                 lessons
            //               </p> */}
            //             </main>
            //             {/* <p className="card__duration">9</p> */}
            //           </main>
            //         </StyledCard>
            //         <main className="flex justify-end mr-4 card__actions cursor-pointer">
            //           <a href={pdf.url} target="_blank"
            //             className="m-1  text-blue-500"
            //           >
            //             View
            //           </a>
            //           <p
            //             onClick={() => {
            //               history.push(
            //                 // `/course/${props.match.params.id}/${lesson._id}/add-pdf/`
            //               );
            //             }}
            //             className="m-1  text-blue-500"
            //           >
            //             Edit
            //           </p>                     
            //            <p
            //             className="m-1 text-red-500 cursor-pointer"
            //             onClick={() => {
            //               handleDelete(pdf);
            //             }}
            //           >
            //             Delete
            //           </p>
            //         </main>
            //       </main>
            //     ))}
            // </main>
          ) : (
            ""
          )}
        </main>
      </main>
    </div>
  );
};

export default withRouter(ManageExamPDF);
