import React, { Component } from "react";
import Header from "../../components/HomePage/Header";
import Landing from "../../components/HomePage/Landing";
import PopularTopics from "../../components/HomePage/PopularTopics";
import Benefits from "../../components/HomePage/Benefits";
import Pricing from "../../components/HomePage/Pricing";
import BeforeFooter from "../../components/HomePage/BeforeFooter";
import Footer from "../../components/HomePage/Footer";
import FAQ from "../../components/Shared/FAQ";
import about from "../../img/about.png";
import user_1 from "../../img/user_1.png";
import {
  PageHeaderContainer,
  PageHeaderText,
} from "../../components/Shared/PageHeader/pageheader.style";
import "./faq.scss";

export default class FAQs extends Component {
  render() {
    return (
      <div className="faq ">
        <Header />
        <div className="pt-32" />
        <main className="boxed__container">
          <main className="mx-auto flex justify-center">
            <PageHeaderContainer className="">
              <PageHeaderText>Frequently Asked Questions</PageHeaderText>
            </PageHeaderContainer>
          </main>
          <main className="flex justify-center">
            <main className="w-full md:w-1/2 ">
              <p className="page__header my-4">Hi, Have any questions?</p>
              <p className="page__content my-4">
                How do you solve an equation of the curve in which subnormal
                varies as the square of the ordinate is (lambda is constant of
                the proportionality)?
              </p>
            </main>
          </main>

          <main className="grey-bg rounded w-full px-8 py-12">
            {[...Array(10)].map((index) => (
              <FAQ key={index} className="mx-4" />
            ))}
          </main>
        </main>

        <Footer />
      </div>
    );
  }
}
