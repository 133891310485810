import "./footer.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button/button.style";
import footer__icon from "../../../img/footer__icon.png";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <main className="">
        <div className=" py-4 footer">
          <main className="boxed__container flex flex-wrap justify-between footer__text">
            <main className="flex flex-wrap">
              <p className="mr-2">Privacy Policy</p>
              <p>Terms of Use</p>
            </main>
            <main className="flex flex-wrap">
              <p>© 2020 Mathematica All Right Reserved</p>
            </main>
            <main className="flex flex-wrap">
              <p>Privacy Policy</p>
              <p className="ml-2">Terms of Use</p>
            </main>
          </main>
        </div>
      </main>
    );
  }
}
