import React from 'react';
import ReactDOM from 'react-dom';
import "./QuizModal.css";

const QuizModal = ({ isOpen, children, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="quiz-modal-overlay">
      <div className="quiz-modal-content">
        <button onClick={onClose} className="quiz-modal-close-button text-black">Close Quiz Editor</button>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default QuizModal;