import React, { Component, useState } from "react";
import "./bubble.scss";
import sam from "../../../img/sam.png";

const ChatBubble = (props) => {
  const { chat } = props;
  const user = JSON.parse(localStorage.getItem("maths-app-user"));
  return (
    <main
      className={`chat__bubble my-4 w-full ${
        user.id == chat.sender.user_id
          ? "flex justify-start"
          : "flex justify-end"
      }`}
    >
      <main className="flex  w-full md:w-2/5 items-end px-2">
        {chat && chat.sender && chat.sender.profile_picture ? (
          <img
            src={chat.sender.profile_picture}
            alt="User Profile Picture"
            className="rounded-full  h-8 w-8 "
          />
        ) : (
          <p className="text-center">
            <i className="fas fa-2x text-black fa-user-circle text-center mx-auto"></i>
          </p>
        )}

        <main className="ml-2 bubble p-3">{chat?.message}</main>
      </main>
    </main>
  );
};
export default ChatBubble;
