import axios from "axios";
import React, { Component, useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// request interceptor
instance.interceptors.request.use(
  (config, reqAuth) => {
    return config;
  },
  (error) => { }
);

const token = localStorage.getItem("maths-user");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};
const get = async (url, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.get(url, config)
      : await instance.get(url);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const post = async (url, payload, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.post(url, payload, config)
      : await instance.post(url, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const patch = async (url, payload, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.patch(url, payload, config)
      : await instance.patch(url, payload);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const put = async (url, payload = {}, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.put(url, payload, config)
      : await instance.put(url, payload);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const del = async (url, payload = {}, requireAuth = true) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };
    let response = requireAuth
      ? await instance.delete(url, config)
      : await instance.delete(url, { data: payload });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const handleError = async (error) => {
  return error.response
  // let message;
  // if (typeof error.response.data.message == "string") {
  //   message = error.response.data.message;
  // } else {
  //   message = error.response.data.message.join(",");
  // }
  // return message;
};

// const handleError = async (error) => {

//   console.log({error})

//   // if(error.response.status == 404) {
//   //   window.location.href = '/not-found';
//   // }

//   if(error.response.status == 401) {
//     localStorage.clear();

//     // toast.error('Sorry, you must be logged in to continue');

//     window.location.href = '/';
//   }
//   return handleError(error)

// };

export { get, post, put, handleError, del, patch };
