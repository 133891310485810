import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import { StyledButton } from "../../components/Shared/Button/button.style";
import "./index.scss";
import lesson1 from "../../img/lesson1.png";
import lesson2 from "../../img/lesson2.png";
import lesson3 from "../../img/lesson3.png";
import lesson4 from "../../img/lesson4.png";
import play from "../../img/play.png";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import { api, get, post } from "../../utils/api";
import { useMutation, useQueries, useQuery } from "react-query";

const Glossary = (props) => {
  const history = useHistory();

  const token = localStorage.getItem("maths-user");
  const [search, setSearch] = useState("");
  const [lessons, setLessons] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    isLoading: isLoadingStatus,
    error: statusError,
    data: statusData,
  } = useQuery("fetchGlossary", () =>
    get("/courses/glossary", true).then((res) => {
      const keys = Object.keys(res.data.glossary);
      const values = Object.values(res.data.glossary);
      const data = keys.map((key, index) => {
        return {
          key,
          data: values[index],
        };
      });
      setGlossary(data);
    })
  );

  const toSearch = (item) => {
    props.history.push(`glossary/search/${item}`);
  };
  return (
    <div className="lessons__page ">
      <Header />
      <div className="py-8" />
      <main className="py-16 w-full ">
        <div className="my-12" />
        <main className="flex flex-wrap w-full justify-center">
          <input
            type="text"
            className="form__border w-full md:w-2/5"
            placeholder="e.g Decimals"
            onChange={(e) => setSearch(e.target.value)}
          />
          <StyledButton
            className="ml-2"
            type="submit"
            borderRadius="3px"
            backgroundColor="#286cff"
            onClick={() => {
              toSearch(search);
            }}
            disabled={loading ? true : false}
          >
            {loading ? <Loader /> : "Search"}
          </StyledButton>
        </main>
        <main className=" my-20 flex flex-wrap boxed__container top__wrapper ">
          {glossary &&
            glossary.map((lesson, index) => (
              <main key={index} className="w-full md:w-1/4 p-4">
                <p className="header__text ">{lesson.key}</p>
                {lesson.data.map((item, i) => (
                  <main
                    className=" forum__subheader"
                    key={i}
                    className="w-full md:w-1/4 px-2"
                  >
                    <p
                      className="cursor-pointer"
                      onClick={() => {
                        toSearch(item);
                      }}
                    >
                      {item}
                    </p>
                  </main>
                ))}
              </main>
            ))}
        </main>

        <main className="mx-auto">
          {!glossary || !glossary.length ? (
            <main>
              <Loader />
            </main>
          ) : (
            ""
          )}
        </main>
      </main>
    </div>
  );
};

export default Glossary;
