import React, { Component, useState } from "react";
import "./faq.scss";

const FAQ = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main className="faq__wrapper px-8 py-2">
      <main className="bg-white p-4 flex justify-between">
        <p className="title">What is Mathematica?</p>
        <p
          className="cursor-pointer"
          onClick={() => {
            toggleOpen();
          }}
        >
          {isOpen ? "-" : "+"}
        </p>
      </main>

      {isOpen ? (
        <main className="content p-4 mb-3 mt-4">
          Mathematica is a solution that automates cooperative processes like
          loans management, accounting and reporting it is also an efficient way
          to manage savings. Mathematica provides companies and individuals an
          easy and efficient way to manage their savings, loans, members
          contribution. It can also generate standard reports on all cooperative
          operations.
        </main>
      ) : (
        ""
      )}
    </main>
  );
};
export default FAQ;
